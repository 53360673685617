import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Icon from '@wtag/rcl-icon';
import Input from '@wtag/rcl-input';
import { debounce } from 'lodash';
import classNames from 'classnames';

import { itemsPerPage, maxPageNumberForFullLoad } from '../../variables';
import Header from '../common/Header';
import Paginate from '../common/Paginate';
import EmptyContentPlaceholder from '../common/EmptyContentPlaceholder';
import IconButton from '../rclComponents/IconButton';
import MetaIcon from '../icon/Icon';
import {
  GET_AIRLINES_WITH_UNVERIFIED_FARE_BRANDS,
  GET_CURSORS_FOR_AIRLINES,
} from '../../graphql/queries/airline';
import GET_COUNT_OF_TOTAL_CURSORS from '../../graphql/queries/totalCursorsCount';
import {
  GET_CURSORS_FOR_UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRANDS,
  GET_TOTAL_UNVERIFIED_SUPPLIER_FARE_BRANDS,
  GET_UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRANDS,
} from '../../graphql/queries/unverifiedSupplierFareBrand';
import { DELETE_UNVERIFIED_SUPPLIER_FARE_BRAND } from '../../graphql/mutations/unverifiedSupplierFareBrand';
import SpinnerIcon from '../../assets/icons/spinner.svg';
import AirlineWithUnverifiedFareBrandRow from './AirlineWithUnverifiedFareBrandRow';
import isEntityAvailable from '../../helpers/isEntityAvailable';
import Button from '@wtag/rcl-button';
import { Tabs, Tab, TabList, TabPanel } from '../rclComponents/Tabs';

const FareBrandVerificationList = () => {
  const { t } = useTranslation();

  const [cursorState, setCursorState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(3);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [searchParam, setSearchParam] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [cursors, setCursors] = useState([]);
  const [cursorFetchNumber, setCursorFetchNumber] = useState(itemsPerPage * 4);
  const [requiredLengthToSearch, setRequiredLengthToSearch] = useState(2);
  const [sortAttribute, setSortAttribute] = useState('');
  const [sortAttributeDirection, setSortAttributeDirection] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const AIRLINE_MODEL = 'airlines';
  const UNVERIFIED_SUPPLIER_FARE_BRAND_MODEL = 'unverifiedSupplierFareBrands';
  const UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRAND_MODEL = 'unmatchedUnverifiedSupplierFareBrands';

  const { loading: totalUnverifiedFareBrandLoading, data: unverifiedFareBrandCount } = useQuery(
    GET_TOTAL_UNVERIFIED_SUPPLIER_FARE_BRANDS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const queryContainer = {
    airlines: {
      fetchAll: GET_AIRLINES_WITH_UNVERIFIED_FARE_BRANDS,
      getCursors: GET_CURSORS_FOR_AIRLINES,
    },
    unmatchedUnverifiedSupplierFareBrands: {
      fetchAll: GET_UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRANDS,
      getCursors: GET_CURSORS_FOR_UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRANDS,
    },
  };

  const modelType = tabIndex === 0 ? AIRLINE_MODEL : UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRAND_MODEL;

  const cursorData = useQuery(queryContainer[modelType].getCursors, {
    variables: {
      first: cursorFetchNumber,
      after: currentCursor,
      query: searchParam,
      currentSortAttribute: sortAttribute,
      currentSortAttributeDirection: sortAttributeDirection,
      withUnverifiedFareBrand: true,
    },
    fetchPolicy: 'network-only',
  });

  const cursorCountData = useQuery(GET_COUNT_OF_TOTAL_CURSORS, {
    variables: {
      modelName:
        tabIndex === 0
          ? UNVERIFIED_SUPPLIER_FARE_BRAND_MODEL
          : UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRAND_MODEL,
      query: searchParam,
    },
  });

  const { client, loading, data } = useQuery(queryContainer[modelType].fetchAll, {
    variables: {
      first: itemsPerPage,
      after: currentCursor,
      query: searchParam,
      currentSortAttribute: sortAttribute,
      currentSortAttributeDirection: sortAttributeDirection,
      withUnverifiedFareBrand: true,
    },
    fetchPolicy: 'no-cache',
  });

  const [deleteUnverifiedSupplierFareBrand] = useMutation(DELETE_UNVERIFIED_SUPPLIER_FARE_BRAND);

  const onUnverifiedFareBrandDelete = id => {
    window.confirm(t('fareBrands.index.confirmDelete')) &&
      deleteUnverifiedSupplierFareBrand({
        variables: { id },
      }).then(() => resetAirlines());
  };

  const callSearchParam = useRef(
    debounce(async inputParam => {
      setSearchParam(inputParam);
    }, 1500),
  );

  const resetSearch = () => {
    setSearchParam('');
    setSearchValue('');
  };

  const postIcon = () => {
    if (loading) {
      return <img className="airline-list__search-post-icon" src={SpinnerIcon} alt="spinner" />;
    }
    return (
      <IconButton
        size="large"
        className="airline-list__search-post-icon"
        isIconOnly={true}
        icon={<MetaIcon name="close" />}
        onClick={resetSearch}
      />
    );
  };

  const forcePage = currentPage - 1;

  const listAttributes = ['name', 'unverifiedFareBrands', 'code', 'lastUpdatedAt'];
  const unmatchedListAttributes = [
    'airlineCode',
    'brandName',
    'supplierName',
    'cabinClass',
    'bookingClass',
  ];

  const changePage = useCallback(
    value => {
      setCurrentPage(value.selected + 1);
      const cursorIndexFromCurrentPage = value.selected * itemsPerPage - 1;
      if (cursorIndexFromCurrentPage < 0) {
        setCurrentCursor(null);
      } else {
        setCurrentCursor(cursors[cursorIndexFromCurrentPage]);
      }
    },
    [cursors],
  );

  useEffect(() => {
    if (cursorCountData && cursorCountData.data && !cursorCountData.loading) {
      const count = cursorCountData.data.totalCursorsCount / itemsPerPage;
      const pageCount = Math.ceil(count);
      setRequiredLengthToSearch(2);
      if (pageCount <= maxPageNumberForFullLoad) {
        setCursorFetchNumber(null);
      } else {
        setCursorFetchNumber(itemsPerPage * 4);
      }
      if (pageCount < currentPage) {
        changePage({ selected: 0 });
      }
      setPageCount(pageCount);
    }
    if (cursorData && cursorData.data && !cursorData.loading && !cursorState) {
      setCursorState(true);
      const oldCursors = Object.assign([], cursors);
      const newCursors = cursorData.data[modelType].edges.map(edge => edge.cursor);
      const finalCursors = oldCursors.concat(
        newCursors.filter(newCursor => oldCursors.indexOf(newCursor) < 0),
      );
      setCursors(finalCursors);
    }
  }, [cursorCountData, cursorData, changePage, currentPage, cursors, cursorState]);

  useEffect(() => {
    setCurrentPage(1);
    setCursorState(false);
    setCurrentCursor(null);
    setCursors([]);
    setCursorFetchNumber(itemsPerPage * 4);
    setSortAttribute('');
    setSortAttributeDirection('');
    resetSearch();
  }, [tabIndex]);

  const resetAirlines = () => client.resetStore();

  const onSearchParamChange = value => {
    setSearchValue(value);

    if (value === '' || value.length >= requiredLengthToSearch) {
      callSearchParam.current(value);
    }
  };

  const setSortingColumn = item => {
    setSortAttribute(item);
    if (sortAttributeDirection === 'down') {
      setSortAttributeDirection('up');
    } else {
      setSortAttributeDirection('down');
    }
  };

  const isColumnSelected = (item, direction) =>
    sortAttribute === item && sortAttributeDirection && sortAttributeDirection === direction;

  const headerWithActions = item => {
    if (item === 'unverifiedFareBrands') {
      return (
        <div className="airline-list__value" key={item}>
          <p>{t(`airlines.attributes.${item}`)}</p>
        </div>
      );
    }

    return (
      <div
        className="airline-list__value airline-list__value-clickable airline-list__value-title"
        key={item}
        role="presentation"
        onClick={() => setSortingColumn(item)}
      >
        <p>{t(`airlines.attributes.${item}`)}</p>

        <div
          className={classNames('airline-list__value-clickable-icon', {
            'airline-list__value-clickable-icon--active': isColumnSelected(item, 'up'),
          })}
        >
          <Icon name="arrowUp" />
        </div>
        <div
          className={classNames('airline-list__value-clickable-icon', {
            'airline-list__value-clickable-icon--active': isColumnSelected(item, 'down'),
          })}
        >
          <Icon name="arrowDown" />
        </div>
      </div>
    );
  };

  const listValue = value => (
    <div className="list__value">
      <div className="list__value-text">{value}</div>
    </div>
  );

  const airlineMatchedFareBrandList = () => (
    <>
      <div className="airline-list__header">
        {listAttributes.map(item => headerWithActions(item))}
      </div>
      <div className="airline-list__table">
        {data[AIRLINE_MODEL].edges.map(data => (
          <AirlineWithUnverifiedFareBrandRow
            data={data.node}
            resetAirlines={resetAirlines}
            deleteUnverifiedFareBrand={onUnverifiedFareBrandDelete}
            key={data.node.id}
          />
        ))}
      </div>
    </>
  );

  const airlineUnmatchedFareBrandList = () => (
    <>
      <div className="list__header">
        {unmatchedListAttributes.map(item => (
          <div className="list__value list__value-title" key={item}>
            <p>{t(`unverifiedSupplierFareBrand.${item}`)}</p>
          </div>
        ))}
        <div className="list__value list__value--actions">
          <p>{t('list.header.actions')}</p>
        </div>
      </div>

      <div className="list__table">
        {data[UNMATCHED_UNVERIFIED_SUPPLIER_FARE_BRAND_MODEL].edges.map(data => (
          <div className="list__data" key={data.node.id}>
            {listValue(data.node.airlineCode)}
            {listValue(data.node.brandName)}
            {listValue(t(`supplierFareBrand.suppliers.${data.node.supplierName}`))}
            {listValue(t(`fareBrands.attributes.${data.node.cabinClass}`))}
            {listValue(data.node.bookingClass)}

            <div className="list__value list__value--actions">
              <Button
                label={t('shared.delete')}
                onClick={() => onUnverifiedFareBrandDelete(data.node.id)}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );

  const getListContent = modelName => (
    <div>
      {loading && <div className="lds-dual-ring" />}
      {!loading && (
        <>
          {isEntityAvailable(data, modelName) ? (
            <>
              <div className="list__pagination">
                <Paginate
                  pageCount={pageCount}
                  onPageChange={changePage}
                  forcePage={forcePage}
                  marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                />
              </div>

              {modelName === AIRLINE_MODEL
                ? airlineMatchedFareBrandList()
                : airlineUnmatchedFareBrandList()}

              <div className="list__pagination">
                <Paginate
                  pageCount={pageCount}
                  onPageChange={changePage}
                  forcePage={forcePage}
                  marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                />
              </div>
            </>
          ) : (
            <EmptyContentPlaceholder
              entityName={t('airlines.attributes.unverifiedFareBrands').toLowerCase()}
              modelName={'Airline'}
            />
          )}
        </>
      )}
    </div>
  );

  const tabKeys = ['matched', 'unmatched'];

  return (
    <div className="airline-list">
      <Header title="nav.fareBrandVerification">
        {!totalUnverifiedFareBrandLoading && (
          <div className="header__children">
            {`${t('unverifiedSupplierFareBrand.totalCounter')} 
            ${unverifiedFareBrandCount.totalUnverifiedSupplierFareBrands}`}
          </div>
        )}
      </Header>
      <div>
        <Input
          label={t('list.search.label')}
          value={searchValue}
          onChange={onSearchParamChange}
          placeholder={t('airlines.index.searchPlaceholder')}
          postIcon={postIcon()}
        />
      </div>

      <Tabs className="list__tabs" onSelect={index => setTabIndex(index)}>
        <TabList>
          {tabKeys.map(item => (
            <Tab key={item}>{t(`unverifiedSupplierFareBrand.${item}`)}</Tab>
          ))}
        </TabList>

        {tabKeys.map(item => (
          <TabPanel key={item}>{getListContent(modelType)}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default FareBrandVerificationList;
