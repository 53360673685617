import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import { Textarea } from '@wtag/react-comp-lib';
import WrappedMap from '../common/WrappedMap';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_RAW_HOTEL, getRawHotel } from '../../graphql/queries/rawHotel';
import { GET_GOOGLE_MAPS_PUBlIC_API_KEY } from '../../graphql/queries/google_maps_public_api_key';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { EDIT_RAW_HOTEL } from '../../graphql/mutations/rawHotel';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const RawHotelForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const { data } = useQuery(GET_GOOGLE_MAPS_PUBlIC_API_KEY);

  const [editRawHotel, callbackDataOnEdit] = useMutation(EDIT_RAW_HOTEL);
  const rawHotelFromId = useQuery(GET_RAW_HOTEL, {
    variables: { id },
  });

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [welltravelCode, setWelltravelCode] = useState('');
  const [provider, setProvider] = useState('');
  const [iso31661, setIso31661] = useState('');
  const [iso31662, setIso31662] = useState('');
  const [imageUrls, setImageUrls] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [additionalContents, setAdditionalContents] = useState('');
  const [supplierCodes, setSupplierCodes] = useState('');
  const [rawData, setRawData] = useState('');

  const onNameChange = value => {
    setName(value);
  };

  const onCodeChange = value => {
    setCode(value);
  };

  const onWelltravelCodeChange = value => {
    setWelltravelCode(value);
  };

  const onProviderChange = value => {
    setProvider(value);
  };

  const onIso31661Change = value => {
    setIso31661(value);
  };

  const onIso31662Change = value => {
    setIso31662(value);
  };

  const onImageUrlsChange = event => {
    setImageUrls(event.target.value);
  };

  const onDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const onAdditionalContentsChange = event => {
    setAdditionalContents(event.target.value);
  };

  const onSupplierCodesChange = event => {
    setSupplierCodes(event.target.value);
  };

  const onRawDataChange = event => {
    setRawData(event.target.value);
  };

  const setLatLong = value => {
    setLatitude(value.lat);
    setLongitude(value.lng);
  };

  useEffect(() => {
    if (rawHotelFromId && rawHotelFromId.data && !rawHotelFromId.loading) {
      const rawHotelData = rawHotelFromId.data.rawHotel;
      setName(rawHotelData.name);
      setCode(rawHotelFromId.data.rawHotel.code);
      setWelltravelCode(rawHotelData.welltravelCode);
      setProvider(rawHotelData.provider);
      setIso31661(rawHotelData.iso31661);
      setIso31662(rawHotelFromId.data.rawHotel.iso31661);
      setImageUrls(JSON.stringify(rawHotelData.imageUrls, undefined, 4));
      setDescription(rawHotelData.description);
      setLatitude(rawHotelData.latitude);
      setLongitude(rawHotelData.longitude);
      setSupplierCodes(JSON.stringify(rawHotelData.supplierCodes, undefined, 4));
      setAdditionalContents(JSON.stringify(rawHotelData.additionalContents, undefined, 4));
      setRawData(JSON.stringify(rawHotelData.rawData, undefined, 4));
    }
  }, [rawHotelFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();
    editRawHotel({
      variables: {
        id,
        name,
        code,
        provider,
        welltravelCode,
        iso31661,
        iso31662,
        additionalContents,
        supplierCodes,
        latitude,
        longitude,
        imageUrls,
        description,
        rawData,
      },
      refetchQueries: [getRawHotel(id)],
    })
      .then(({ data }) => {
        props.history.push(`/admin/raw_hotels/${data.editRawHotel.id}`);
      })
      .catch(() => props.onError());
  }, 500);

  const renderMap = data && data.googleMapsPublicApiKey && latitude && longitude;

  return (
    <div className="common-form">
      <div className="common-form__title">
        {t('shared.editTitle', { entityName: t('rawHotels.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-12">
              <div className="common-form__field raw-hotel-form__name">
                <Input
                  label={t('rawHotels.attributes.name')}
                  value={name}
                  onChange={onNameChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field raw-hotel-form__code">
                <Input
                  label={t('rawHotels.attributes.code')}
                  value={code}
                  onChange={onCodeChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field raw-hotel-form__welltravel_code">
                <Input
                  label={t('rawHotels.attributes.welltravelCode')}
                  value={welltravelCode}
                  onChange={onWelltravelCodeChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field raw-hotel-form__provider">
                <Input
                  label={t('rawHotels.attributes.provider')}
                  value={provider}
                  onChange={onProviderChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field raw-hotel-form__iso31661">
                <Input
                  label={t('rawHotels.attributes.iso31661')}
                  value={iso31661}
                  onChange={onIso31661Change}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field raw-hotel-form__iso31662">
                <Input
                  label={t('rawHotels.attributes.iso31662')}
                  value={iso31662}
                  onChange={onIso31662Change}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field raw-hotel-form__latitude">
                <Input
                  type="number"
                  label={t('rawHotels.attributes.latitude')}
                  value={latitude}
                  onChange={()=> latitude }
                  hint={t('rawHotels.hints.latLonField')}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field raw-hotel-form__longitude">
                <Input
                  type="number"
                  label={t('rawHotels.attributes.longitude')}
                  value={longitude}
                  onChange={()=> longitude }
                  hint={t('rawHotels.hints.latLonField')}
                  isClearable={false}
                />
              </div>
              <div className="common-form__map">
                {renderMap && (
                  <WrappedMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${data.googleMapsPublicApiKey}`}
                    latLong={{ lat: latitude, lng: longitude }}
                    infoWindowContent={name}
                    getLatLong={value => {
                    setLatLong(value);
                    }}
                  />
                )}
              </div>
              <div className="common-form__field raw-hotel-form__description">
                <Textarea
                  label={t('rawHotels.attributes.description')}
                  value={description}
                  onChange={onDescriptionChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field raw-hotel-form__image_urls">
                <Textarea
                  label={`${t('rawHotels.attributes.imageUrls')}(${t('rawHotels.hints.array')})`}
                  value={imageUrls}
                  onChange={onImageUrlsChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field raw-hotel-form__additional_contents">
                <Textarea
                  label={`${t('rawHotels.attributes.additionalContents')}(${t(
                  'rawHotels.hints.json',
                  )})`}
                  value={additionalContents}
                  onChange={onAdditionalContentsChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field raw-hotel-form__supplier_codes">
                <Textarea
                  label={`${t('rawHotels.attributes.supplierCodes')}(${t(
                  'rawHotels.hints.warning',
                  )})`}
                  value={supplierCodes}
                  onChange={onSupplierCodesChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field raw-hotel-form__raw_data">
                <Textarea
                  label={`${t('rawHotels.attributes.rawData')}(${t('rawHotels.hints.json')})`}
                  value={rawData}
                  onChange={onRawDataChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__submit-button">
                <Button label={t('shared.update')} onClick={submitToBackEnd} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(RawHotelForm);
