import React, { useState } from 'react';
import Button from '@wtag/rcl-button';
import { Link } from 'react-router-dom';
import Icon from '@wtag/rcl-icon';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BaseModal from '../rclComponents/BaseModal';

import { MAP_UNVERIFIED_SUPPLIER_FARE_BRAND } from '../../graphql/mutations/unverifiedSupplierFareBrand';

const AirlineWithUnverifiedFareBrandRow = props => {
  const { data, resetAirlines, deleteUnverifiedFareBrand } = props;
  const { t } = useTranslation();

  const listAttributes = ['name', 'unverifiedFareBrands', 'code', 'lastUpdatedAt'];

  const [expand, setExpand] = useState(false);
  const [mapUnverifiedSupplierFareBrand] = useMutation(MAP_UNVERIFIED_SUPPLIER_FARE_BRAND);
  const SUPPLIER_NAME_COLUMN = 'supplierName';
  const CABIN_CLASS_COLUMN = 'cabinClass';

  const onUnverifiedFareBrandMap = (unverifiedFareBrandId, fareBrandId) => {
    mapUnverifiedSupplierFareBrand({
      variables: {
        id: unverifiedFareBrandId,
        fareBrandId: fareBrandId,
      },
    }).then(() => resetAirlines());
  };

  const renderValue = (item, value) => {
    if (item === 'unverifiedFareBrands') {
      return (
        <div
          className="airline-list__data-value airline-list__data-value-clickable"
          key={item}
          role="presentation"
          onClick={() => setExpand(prevState => !prevState)}
        >
          <div>{value.length}</div>
          <div className="airline-list__data-value-clickable-icon">
            {expand ? <Icon name="iconUpChevron" /> : <Icon name="iconDownChevron" />}
          </div>
        </div>
      );
    }

    return (
      <div className="airline-list__data-value" key={item}>
        <div key={item}>{typeof value === 'boolean' ? t(`shared.${value.toString()}`) : value}</div>
      </div>
    );
  };

  const listValue = value => (
    <div className="list__value">
      <div className="list__value-text">{value}</div>
    </div>
  );

  const getAttributeValue = (unverifiedFareBrand, item) => {
    if (item === SUPPLIER_NAME_COLUMN) {
      return t(`supplierFareBrand.suppliers.${unverifiedFareBrand[item]}`);
    } else if (item === CABIN_CLASS_COLUMN) {
      return t(`fareBrands.attributes.${unverifiedFareBrand[item]}`);
    }

    return unverifiedFareBrand[item];
  };

  const renderMapToExistingModal = unverifiedFareBrand => {
    const unverifiedAttrs = ['brandName', SUPPLIER_NAME_COLUMN, CABIN_CLASS_COLUMN, 'bookingClass'];
    const fareBrandAttrs = ['brandName', 'cabinClass', 'bookingClass'];
    const matchedFareBrands = unverifiedFareBrand.matchedFareBrands;

    return (
      <BaseModal
        linkText={t('shared.mapToExisting')}
        linkClassName="button"
        defaultPadding={true}
        header={t('unverifiedSupplierFareBrand.mapModal.header')}
        size="large"
      >
        <div className="airline-list__modal-wrapper">
          <div className="list__header">
            {unverifiedAttrs.map(item => (
              <div className="list__value list__value-title" key={item}>
                <p>{t(`unverifiedSupplierFareBrand.${item}`)}</p>
              </div>
            ))}
          </div>

          <div className="list__table">
            <div className="list__data" key={unverifiedFareBrand.id}>
              {unverifiedAttrs.map(item => (
                <div className="list__value" key={item}>
                  <div className="list__value-text" key={item}>
                    {getAttributeValue(unverifiedFareBrand, item)}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="list__header airline-list__modal-fare-brand">
            <p>{t('unverifiedSupplierFareBrand.mapModal.availableFairBrands')}</p>
          </div>

          <div className="list__header">
            {fareBrandAttrs.map(item => (
              <div className="list__value list__value-title" key={item}>
                <p>{t(`unverifiedSupplierFareBrand.${item}`)}</p>
              </div>
            ))}
            <div className="list__value list__value--actions">
              <p>{t('list.header.actions')}</p>
            </div>
          </div>

          <div className="list__table">
            {matchedFareBrands.map(fareBrand => (
              <div className="list__data" key={fareBrand.id}>
                {listValue(fareBrand.name)}
                {listValue(t(`fareBrands.attributes.${fareBrand.cabinClass.name}`))}
                {listValue(fareBrand.bookingClass.join(', '))}

                <div className="list__value list__value--actions">
                  <Button
                    label={t('unverifiedSupplierFareBrand.mapModal.map')}
                    onClick={() => onUnverifiedFareBrandMap(unverifiedFareBrand.id, fareBrand.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </BaseModal>
    );
  };

  return (
    <React.Fragment key={data.id}>
      <div className="airline-list__data">
        {listAttributes.map(item => renderValue(item, data[item]))}
      </div>

      {expand && (
        <>
          {data.unverifiedFareBrands.map(unverifiedFareBrand => (
            <div
              className="airline-list__data airline-list__data--nested"
              key={unverifiedFareBrand.id}
            >
              <div className="airline-list__data-value">
                <div>{unverifiedFareBrand.brandName}</div>
              </div>
              <div className="airline-list__data-value">
                <div>{t(`supplierFareBrand.suppliers.${unverifiedFareBrand.supplierName}`)}</div>
              </div>
              <div className="airline-list__data-value">
                <div>{t(`fareBrands.attributes.${unverifiedFareBrand.cabinClass}`)}</div>
              </div>
              <div className="airline-list__data-value">
                <div>{unverifiedFareBrand.bookingClass}</div>
              </div>
              <div className="airline-list__data-value airline-list__data-value--nested-actions-wide">
                {unverifiedFareBrand.matchedFareBrands.length > 0 &&
                  renderMapToExistingModal(unverifiedFareBrand)}
                <Link
                  to={`/admin/airlines/${data.id}/unverified_fare_brands/${unverifiedFareBrand.id}/new`}
                >
                  <Button label={t('shared.addNew')} />
                </Link>
                <Button
                  label={t('shared.delete')}
                  onClick={() => deleteUnverifiedFareBrand(unverifiedFareBrand.id)}
                />
              </div>
            </div>
          ))}
        </>
      )}
    </React.Fragment>
  );
};

AirlineWithUnverifiedFareBrandRow.propTypes = {
  data: PropTypes.object,
  resetAirlines: PropTypes.func.isRequired,
  deleteUnverifiedFareBrand: PropTypes.func.isRequired,
};

export default AirlineWithUnverifiedFareBrandRow;
