import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { debounce } from 'lodash';

import {
  GET_ANCILLARY_CATEGORY,
  getAncillaryCategory,
} from '../../graphql/queries/ancillaryCategory';

import {
  CREATE_ANCILLARY_CATEGORY,
  EDIT_ANCILLARY_CATEGORY,
} from '../../graphql/mutations/ancillaryCategory';

import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import clearCache from '../../helpers/clearCache';
import { useTranslation } from 'react-i18next';

const AncillaryCategoryForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createAncillaryCategory, callbackDataOnCreate] = useMutation(CREATE_ANCILLARY_CATEGORY, {
    update: cache => clearCache(cache, /AncillaryCategory/),
  });
  const [editAncillaryCategory, callbackDataOnEdit] = useMutation(EDIT_ANCILLARY_CATEGORY);
  const ancillaryCategoryFromId = useQuery(GET_ANCILLARY_CATEGORY, {
    variables: { id },
    skip: isNewRecord,
  });

  const [identifier, setIdentifier] = useState('');

  const onIdentifierChange = value => {
    setIdentifier(value);
  };

  useEffect(() => {
    if (
      ancillaryCategoryFromId &&
      ancillaryCategoryFromId.data &&
      !ancillaryCategoryFromId.loading
    ) {
      setIdentifier(ancillaryCategoryFromId.data.ancillaryCategory.identifier);
    }
  }, [ancillaryCategoryFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createAncillaryCategory({
        variables: { identifier },
      })
        .then(({ data }) => {
          props.history.push(`/admin/ancillary_categories/${data.createAncillaryCategory.id}`);
        })
        .catch(() => props.onError());
    } else {
      editAncillaryCategory({
        variables: { id, identifier },
        refetchQueries: [getAncillaryCategory(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/ancillary_categories/${data.editAncillaryCategory.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('ancillaryCategories.show.title') })
          : t('shared.editTitle', { entityName: t('ancillaryCategories.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-12 col-bleed-y">
              <div className="common-form__field ancillaryCategory-form__identifier">
                <Input
                  label={t('ancillaryCategories.attributes.identifier')}
                  value={identifier}
                  onChange={onIdentifierChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(AncillaryCategoryForm);
