let baseBackendURL = window.location.origin;
let loadPath = `${baseBackendURL}/admin/locales/{{lng}}/{{ns}}.json`;
let debug = false;
if (process.env.NODE_ENV === 'development') {
  baseBackendURL = 'http://localhost:3330';
  loadPath = 'http://localhost:3331/admin/locales/{{lng}}/{{ns}}.json';
  debug = true;
}

const config = {
  urls: {
    baseBackendURL,
    graphQL: `${baseBackendURL}/graphql`,
    login: `${baseBackendURL}/login`,
    logout: `${baseBackendURL}/logout`,
  },
  i18next: {
    loadPath,
    debug,
  },
};

export default config;
