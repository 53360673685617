import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_CONTINENT, getContinent } from '../../graphql/queries/continent';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { CREATE_CONTINENT, EDIT_CONTINENT } from '../../graphql/mutations/continent';
import clearCache from '../../helpers/clearCache';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

const ContinentForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createContinent, callbackDataOnCreate] = useMutation(CREATE_CONTINENT, {
    update: cache => clearCache(cache, /Continent/),
  });
  const [editContinent, callbackDataOnEdit] = useMutation(EDIT_CONTINENT);
  const continentFromId = useQuery(GET_CONTINENT, {
    variables: { id },
    skip: isNewRecord,
  });

  const [name, setName] = useState('');
  const [code, setCode] = useState('');

  const onNameChange = value => {
    setName(value);
  };

  const onCodeChange = value => {
    setCode(value);
  };

  useEffect(() => {
    if (continentFromId && continentFromId.data && !continentFromId.loading) {
      setName(continentFromId.data.continent.name);
      setCode(continentFromId.data.continent.code);
    }
  }, [continentFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createContinent({
        variables: { name, code },
      })
        .then(({ data }) => {
          props.history.push(`/admin/continents/${data.createContinent.id}`);
        })
        .catch(() => props.onError());
    } else {
      editContinent({
        variables: { id, name, code },
        refetchQueries: [getContinent(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/continents/${data.editContinent.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('continents.show.title') })
          : t('shared.editTitle', { entityName: t('continents.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-12 col-bleed-y">
              <div className="common-form__field continent-form__name">
                <Input
                  label={t('continents.attributes.name')}
                  value={name}
                  onChange={onNameChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field continent-form__code">
                <Input
                  label={t('continents.attributes.code')}
                  value={code}
                  onChange={onCodeChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(ContinentForm);
