import React, { useState } from 'react';
import Button from '@wtag/rcl-button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';
import { all, one } from '../../graphql/queries/prepareQueryParams';
import { GET_FAQ, GET_FAQ_CATEGORIES, GET_FAQS, getFaq } from '../../graphql/queries/faq';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { DELETE_FAQ, EDIT_FAQ } from '../../graphql/mutations/faq';

const FaqData = props => {
  const { t } = useTranslation();
  const { data, currentCursor, searchParam } = props;
  const [id, setId] = useState('');
  const notId = !id;

  const faqFromId = useQuery(GET_FAQ, {
    variables: { id },
    skip: notId,
  });

  if (faqFromId && faqFromId.data && !faqFromId.loading) {
    const faqData = faqFromId.data.faq;
    data['subFaqs'] = faqData.nestedFaqs;
  }

  const [expand, setExpand] = useState(false);
  const [deleteFaq] = useMutation(DELETE_FAQ);
  const [editFaq] = useMutation(EDIT_FAQ);

  const onFaqDelete = id => {
    if (data.parentId) {
      window.confirm(t('faqs.index.confirmDelete')) &&
        deleteFaq({
          variables: { id },
          refetchQueries: [one(GET_FAQ, data.parentId), all(GET_FAQ_CATEGORIES)],
        });
    } else {
      window.confirm(t('faqs.index.confirmDelete')) &&
        deleteFaq({
          variables: { id },
          refetchQueries: [
            all(GET_FAQS, currentCursor, searchParam),
            all(GET_FAQS, currentCursor),
            all(GET_FAQ_CATEGORIES),
          ],
        });
    }
  };

  const processExpansion = () => {
    if (expand) {
      delete data.subFaqs;
      setId('');
      setExpand(false);
    } else {
      setId(data.id);
      setExpand(true);
    }
  };

  const updateFaqOrder = (faq, down = false) => {
    const { id, title, parentId } = faq;
    let order = faq.order;
    order = down ? order - 1 : order + 1;

    if (data.parentId) {
      editFaq({
        variables: { id, title, order, userId: props.userId },
        refetchQueries: [
          getFaq(parentId),
          getFaq(id),
        ],
      });
    } else {
      editFaq({
        variables: { id, title, order, userId: props.userId },
        refetchQueries: [
          getFaq(id),
          all(GET_FAQS, currentCursor, searchParam),
          all(GET_FAQS, currentCursor),
        ],
      });
    }
  };

  return (
    <React.Fragment key={data.id}>
      <div className="faqs-list__data">
        <div className="faqs-list__value-topic" onClick={processExpansion}>
          <p style={{ paddingLeft: props.depth * 20 + 'px' }}>{data['title']}</p>
          <div className="faqs-list__value-icon">
            {data.nestedFaqs &&
              data.nestedFaqs.length > 0 &&
              (expand ? <Icon name="ArrowUp" /> : <Icon name="ArrowDown" />)}
          </div>
        </div>
        <div className="faqs-list__value">
          <Button label={t('shared.up')} onClick={() => updateFaqOrder(data)} />
          <div className="faqs-list__value-order">{data.order}</div>
          <Button
            label={t('shared.down')}
            type="darkOverImage"
            disabled={!data.order}
            onClick={() => updateFaqOrder(data, true)}
          />
        </div>
        <div className="faqs-list__value">
          {data.thumbsUp > 0 || data.thumbsDown > 0 ? (
            <p>
              {data.thumbsUp}/{data.thumbsDown}
            </p>
          ) : (
            <p>__</p>
          )}
        </div>
        <div className="faqs-list__value faqs-list__value--actions">
          <Link to={`/admin/faqs/${data.id}`}>
            <Button label={t('shared.show')} />
          </Link>
          <Link to={`/admin/faqs/${data.id}/edit`}>
            <Button label={t('shared.edit')} />
          </Link>
          <Button label={t('shared.delete')} onClick={() => onFaqDelete(data.id)} />
        </div>
      </div>
      {data.subFaqs &&
        data.subFaqs.length > 0 &&
        data.subFaqs.map(data => (
          <React.Fragment key={data.id}>
            <FaqData
              data={data}
              currentCursor={props.currentCursor}
              searchParam={props.searchParam}
              userId={props.userId}
              depth={props.depth + 1}
            />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

FaqData.propTypes = {
  data: PropTypes.object.isRequired,
  currentCursor: PropTypes.string,
  searchParam: PropTypes.string,
  userId: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
};

export default FaqData;
