import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import { Textarea } from '@wtag/react-comp-lib';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_ANCILLARY_SERVICE } from '../../graphql/queries/ancillaryService';
import { GET_ANCILLARY_CATEGORIES } from '../../graphql/queries/ancillaryCategory';

import {
  CREATE_ANCILLARY_SERVICE,
  EDIT_ANCILLARY_SERVICE,
} from '../../graphql/mutations/ancillaryService';

import { useTranslation } from 'react-i18next';
import imageUrl from '../../helpers/imageUrl';
import clearCache from '../../helpers/clearCache';
import FileUpload from '../common/FileUpload';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getAncillaryService } from '../../graphql/queries/ancillaryService';
import { queryVariables } from '../../graphql/queries/prepareQueryParams';
import NoResultsFound from '../common/NoResultsFound';
import { debounce } from 'lodash';

const AncillaryServiceForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createAncillaryService, callbackDataOnCreate] = useMutation(CREATE_ANCILLARY_SERVICE, {
    update: cache => clearCache(cache, /AncillaryService/),
  });
  const [editAncillaryService, callbackDataOnEdit] = useMutation(EDIT_ANCILLARY_SERVICE);
  const { loading, data } = useQuery(GET_ANCILLARY_CATEGORIES, queryVariables());
  const ancillaryServiceFromId = useQuery(GET_ANCILLARY_SERVICE, {
    variables: { id },
    skip: isNewRecord,
  });

  const [ssrCode, setSsrCode] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [ancillaryCategoryId, setAncillaryCategoryId] = useState('');
  const [fullDescription, setFullDescription] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [previousImage, setPreviousImage] = useState(null);

  const onSsrCodeChange = value => {
    setSsrCode(value);
  };

  const onServiceTypeChange = value => {
    setServiceType(value);
  };

  const onAncillaryCategoryIdChange = selectedOption => {
    setAncillaryCategoryId(selectedOption.value);
  };

  const onFullDescriptionChange = event => {
    setFullDescription(event.target.value);
  };

  const onDescriptionChange = event => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    if (ancillaryServiceFromId && ancillaryServiceFromId.data && !ancillaryServiceFromId.loading) {
      setSsrCode(ancillaryServiceFromId.data.ancillaryService.ssrCode);
      setServiceType(ancillaryServiceFromId.data.ancillaryService.serviceType);
      setAncillaryCategoryId(ancillaryServiceFromId.data.ancillaryService.ancillaryCategory.id);
      setFullDescription(ancillaryServiceFromId.data.ancillaryService.fullDescription);
      setDescription(ancillaryServiceFromId.data.ancillaryService.description);
      setPreviousImage(ancillaryServiceFromId.data.ancillaryService.imageUrls.large);
    }
  }, [ancillaryServiceFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createAncillaryService({
        variables: {
          ssrCode,
          serviceType,
          ancillaryCategoryId,
          fullDescription,
          description,
          image,
        },
      })
        .then(({ data }) => {
          props.history.push(`/admin/ancillary_services/${data.createAncillaryService.id}`);
        })
        .catch(() => props.onError());
    } else {
      editAncillaryService({
        variables: {
          id,
          ssrCode,
          serviceType,
          ancillaryCategoryId,
          fullDescription,
          description,
          image,
        },
        refetchQueries: [getAncillaryService(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/ancillary_services/${data.editAncillaryService.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  let ancillaryCategoryOptions;

  if (loading) {
    ancillaryCategoryOptions = [];
  } else {
    ancillaryCategoryOptions = data.ancillaryCategories.edges.map(ancillaryCategory => ({
      value: ancillaryCategory.node.id.toString(),
      label: ancillaryCategory.node.identifier,
    }));
  }

  const onFileUpload = files => {
    setImage(files[0]);
  };

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('ancillaryServices.show.title') })
          : t('shared.editTitle', { entityName: t('ancillaryServices.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field ancillary-service-form__ssr-code">
                <Input
                  label={t('ancillaryServices.attributes.ssrCode')}
                  value={ssrCode}
                  onChange={onSsrCodeChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field ancillary-service-form__service-type">
                <Input
                  label={t('ancillaryServices.attributes.serviceType')}
                  value={serviceType}
                  onChange={onServiceTypeChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field ancillary-service-form__ancillary-category">
                <SelectBox
                  label={t('ancillaryServices.attributes.ancillaryCategory')}
                  value={ancillaryCategoryOptions.find(option =>
                      option.value === ancillaryCategoryId.toString())}
                  options={ancillaryCategoryOptions}
                  onChange={onAncillaryCategoryIdChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-12 col-bleed-y">
              <div className="common-form__field ancillary-service-form__full-description">
                <Textarea
                  label={t('ancillaryServices.attributes.fullDescription')}
                  value={fullDescription}
                  onChange={onFullDescriptionChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field ancillary-service-form__description">
                <Textarea
                  label={t('ancillaryServices.attributes.description')}
                  value={description}
                  onChange={onDescriptionChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
          <div className="col-6 col-bleed-y">
            <h2>{t('ancillaryServices.attributes.image')}</h2>
            {previousImage && (
              <div className="common-form__previous-image">
                <img src={imageUrl(previousImage)} alt="preview" />
              </div>
            )}
            <FileUpload onFileUpload={onFileUpload} multiple={false} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(AncillaryServiceForm);
