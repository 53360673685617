import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_AIRCRAFT_TYPE } from '../../graphql/queries/aircraftType';
import { CREATE_AIRCRAFT_TYPE, EDIT_AIRCRAFT_TYPE } from '../../graphql/mutations/aircraftType';
import { useTranslation } from 'react-i18next';
import imageUrl from '../../helpers/imageUrl';
import clearCache from '../../helpers/clearCache';
import FileUpload from '../common/FileUpload';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getAircraftType } from '../../graphql/queries/aircraftType';
import UnsplashImageSearch from '../unsplashImageSearch';
import { debounce } from 'lodash';

const AircraftTypeForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createAircraftType, callbackDataOnCreate] = useMutation(CREATE_AIRCRAFT_TYPE, {
    update: cache => clearCache(cache, /Aircraft/),
  });
  const [editAircraftType, callbackDataOnEdit] = useMutation(EDIT_AIRCRAFT_TYPE);
  const aircraftTypeFromId = useQuery(GET_AIRCRAFT_TYPE, {
    variables: { id },
    skip: isNewRecord,
  });

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [image, setImage] = useState(null);
  const [previousImage, setPreviousImage] = useState(null);

  const onNameChange = value => {
    setName(value)
  };

  const onCodeChange = value => {
    setCode(value);
  };

  const onImageFileUpload = files => {
    setImage(files[0]);
  };

  useEffect(() => {
    if (aircraftTypeFromId && aircraftTypeFromId.data && !aircraftTypeFromId.loading) {
      setName(aircraftTypeFromId.data.aircraftType.name);
      setCode(aircraftTypeFromId.data.aircraftType.code);
      setPreviousImage(aircraftTypeFromId.data.aircraftType.imageUrls.large);
    }
  }, [aircraftTypeFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createAircraftType({
        variables: {
          name,
          code,
          image,
        },
      })
        .then(({ data }) => {
          props.history.push(`/admin/aircraft_types/${data.createAircraftType.id}`);
        })
        .catch(() => props.onError());
    } else {
      editAircraftType({
        variables: {
          id,
          name,
          code,
          image,
        },
        refetchQueries: [getAircraftType(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/aircraft_types/${data.editAircraftType.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('aircraftTypes.show.title') })
          : t('shared.editTitle', { entityName: t('aircraftTypes.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-12 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field aircraft-type-form__name">
                <Input
                  label={t('aircraftTypes.attributes.name')}
                  value={name}
                  onChange={onNameChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field aircraft-type-form__code">
                <Input
                  label={t('aircraftTypes.attributes.code')}
                  value={code}
                  onChange={onCodeChange}
                  placeholder={t('aircraftTypes.new.codePlaceholder')}
                  isClearable={false}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
          <div className="col-6 col-bleed-y">
            <h2>{t('aircraftTypes.attributes.image')}</h2>
            {previousImage && (
              <div className="common-form__previous-image">
                <img src={imageUrl(previousImage)} alt="preview" />
              </div>
            )}
            <FileUpload onFileUpload={onImageFileUpload} multiple={false} />
            {!isNewRecord && (
              <UnsplashImageSearch
                setPreviousImage={setPreviousImage}
                imageableType={'AircraftType'}
                imageableId={id}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(AircraftTypeForm);
