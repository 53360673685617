import React from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { useQuery } from '@apollo/react-hooks';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect } from 'react-router-dom';
import './stylesheet/index.scss';

import AirportList from './components/airport';
import Airport from './components/airport/Airport';
import AirportForm from './components/airport/AirportForm';

import CountryList from './components/country';
import Country from './components/country/Country';
import CountryForm from './components/country/CountryForm';

import ContinentList from './components/continent';
import Continent from './components/continent/Continent';
import ContinentForm from './components/continent/ContinentForm';

import CityList from './components/city';
import City from './components/city/City';
import CityForm from './components/city/CityForm';

import StaticMessageList from './components/staticMessage';
import StaticMessageForm from './components/staticMessage/StaticMessageForm';
import StaticMessage from './components/staticMessage/StaticMessage';

import AncillaryServiceList from './components/ancillaryService';
import AncillaryService from './components/ancillaryService/AncillaryService';
import AncillaryServiceForm from './components/ancillaryService/AncillaryServiceForm';

import AncillaryCategoryList from './components/ancillaryCategory';
import AncillaryCategory from './components/ancillaryCategory/AncillaryCategory';
import AncillaryCategoryForm from './components/ancillaryCategory/AncillaryCategoryForm';

import AirlineList from './components/airline';
import Airline from './components/airline/Airline';
import AirlineForm from './components/airline/AirlineForm';

import FareBrandForm from './components/fareBrand/FareBrandForm';
import FareBrand from './components/fareBrand/FareBrand';

import FeatureList from './components/feature';
import Feature from './components/feature/Feature';
import FeatureForm from './components/feature/FeatureForm';

import CarRentalCompanyList from './components/carRentalCompany';
import CarRentalCompany from './components/carRentalCompany/CarRentalCompany';
import CarRentalCompanyForm from './components/carRentalCompany/CarRentalCompanyForm';

import BannerList from './components/banner';
import Banner from './components/banner/Banner';
import BannerForm from './components/banner/BannerForm';

import PlaceSynonymList from './components/placeSynonym';
import PlaceSynonym from './components/placeSynonym/PlaceSynonym';
import PlaceSynonymForm from './components/placeSynonym/PlaceSynonymForm';

import Place from './components/place/Place';
import PlaceForm from './components/place/PlaceForm';

import FlightList from './components/flight';
import Flight from './components/flight/Flight';
import FlightForm from './components/flight/FlightForm';

import RawHotelList from './components/rawHotel';
import RawHotel from './components/rawHotel/RawHotel';
import RawHotelForm from './components/rawHotel/RawHotelForm';

import HotelCombinedDatabaseList from './components/hotelCombinedDatabase';
import HotelCombinedDatabase from './components/hotelCombinedDatabase/HotelCombinedDatabase';
import HotelCombinedDatabaseForm from './components/hotelCombinedDatabase/HotelCombinedDatabaseForm';

import HumanVerifiableHotels from './components/humanVerifiedHotels/HumanVerifiableHotels';
import UnmatchedHumanVerifiableHotels from './components/humanVerifiedHotels/UnmatchedHumanVerifiableHotels';

import AircraftTypeList from './components/aircraftType';
import AircraftType from './components/aircraftType/AircraftType';
import AircraftTypeForm from './components/aircraftType/AircraftTypeForm';

import GlossaryList from './components/glossary';
import Glossary from './components/glossary/Glossary';
import GlossaryForm from './components/glossary/GlossaryForm';

import FaqList from './components/faq';
import Faq from './components/faq/Faq';
import FaqForm from './components/faq/FaqForm';
import FaqLabelForm from './components/faq/FaqLabelForm';

import PlatformFeatureList from './components/platformFeature';
import PlatformFeature from './components/platformFeature/PlatformFeature';
import PlatformFeatureForm from './components/platformFeature/PlatformFeatureForm';
import PlatformFeatureLabelForm from './components/platformFeature/PlatformFeatureLabelForm';

import WelltravelNewsList from './components/welltravelNews';
import WelltravelNews from './components/welltravelNews/WelltravelNews';
import WelltravelNewsForm from './components/welltravelNews/WelltravelNewsForm';

import TravelNewsList from './components/travelNews';
import TravelNews from './components/travelNews/TravelNews';
import TravelNewsForm from './components/travelNews/TravelNewsForm';

import VisaProcurementCategoryList from './components/visaProcurementCategory';
import VisaProcurementCategory from './components/visaProcurementCategory/VisaProcurementCategory';
import VisaProcurementCategoryForm from './components/visaProcurementCategory/VisaProcurementCategoryForm';

import FareBrandVerificationList from './components/fareBrandVerification';

import ComingSoon from './components/common/ComingSoon';

import Dashboard from '../src/components/dashboard/Dashboard';
import LanguageSelection from '../src/components/common/LanguageSelection';
import { useTranslation } from 'react-i18next';
import { GET_CURRENT_USER } from './graphql/queries/currentUser';
import config from './config';

const cache = new InMemoryCache();

const link = createUploadLink({
  uri: config.urls.graphQL,
  credentials: 'include',
});

const client = new ApolloClient({
  cache,
  link,
});

const App = () => {
  const { t } = useTranslation();

  const { loading, data } = useQuery(GET_CURRENT_USER);

  const userLoggedIn = !loading && data?.currentUser;

  return (
    <Router>
      <div className="App">
        <div className="App-header">
          <nav className="nav">
            <div className="nav__title">
              <h4>{t('nav.title')}</h4>
            </div>
            <LanguageSelection />
            {userLoggedIn && (
              <div className="nav__user">
                {data.currentUser.name}
                {data.currentUser.imageUrl && (
                  <img src={data.currentUser.imageUrl} alt="User Avatar" />
                )}
              </div>
            )}
            <ul className="nav__links">
              {!userLoggedIn && (
                <a className="nav__links--text-style" href={config.urls.login}>
                  {t('nav.login')}
                </a>
              )}
              {userLoggedIn && (
                <>
                  <a className="nav__links--text-style" href={config.urls.logout}>
                    {t('nav.logout')}
                  </a>
                  <Link className="nav__links--text-style" to={'/admin/'}>
                    {t('nav.home')}
                  </Link>
                </>
              )}
            </ul>
          </nav>
        </div>
        {!loading && !userLoggedIn && <Redirect to={'/admin/'} />}
        {!loading && userLoggedIn && (
          <div className="App-body">
            <div className="grid">
              <div className="col-lg-1 col-md-2 col-sm-2 col-xs-2 side-nav">
                <div className="side-nav__content">
                  <div className="side-nav__content--header">{t('nav.navigation')}</div>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/aircraft_types'}>
                    {t('nav.aircraftTypes')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/airlines'}>
                    {t('nav.airlines')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/airports'}>
                    {t('nav.airports')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/ancillary_categories'}>
                    {t('nav.ancillaryCategories')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/ancillary_services'}>
                    {t('nav.ancillaryServices')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/banners'}>
                    {t('nav.banners')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/car_rental_companies'}>
                    {t('nav.carRentalCompanies')}
                  </NavLink>
                  <NavLink
                    className="side-nav__content--links"
                    activeClassName="side-nav__content--active"
                    to={'/admin/cities'}
                  >
                    {t('nav.cities')}
                  </NavLink>
                  <NavLink
                    className="side-nav__content--links"
                    activeClassName="side-nav__content--active"
                    to={'/admin/continents'}
                  >
                    {t('nav.continents')}
                  </NavLink>
                  <NavLink
                    className="side-nav__content--links"
                    activeClassName="side-nav__content--active"
                    to={'/admin/countries'}
                  >
                    {t('nav.countries')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/fare_brand_verification'}>
                    {t('nav.fareBrandVerification')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/faqs'}>
                    {t('nav.faqs')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/features'}>
                    {t('nav.features')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/flights'}>
                    {t('nav.flights')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/glossaries'}>
                    {t('nav.glossaries')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/place_synonyms'}>
                    {t('nav.placeSynonyms')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/platform_features'}>
                    {t('nav.platformFeatures')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/travel_news'}>
                    {t('nav.travelNews')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/static_messages'}>
                    {t('nav.staticMessages')}
                  </NavLink>
                  <NavLink className="side-nav__content--links" activeClassName='side-nav__content--active' to={'/admin/welltravel_news'}>
                    {t('nav.welltravelNews')}
                  </NavLink>
                  <NavLink
                    className="side-nav__content--links"
                    activeClassName="side-nav__content--active"
                    to={'/admin/visa_procurement_categories'}
                  >
                    {t('nav.visaProcurementCategories')}
                  </NavLink>
                  <NavLink
                    className="side-nav__content--links"
                    activeClassName="side-nav__content--active"
                    to={'/admin/raw_hotels'}
                  >
                    {t('nav.rawHotels')}
                  </NavLink>
                  <NavLink
                    className="side-nav__content--links"
                    activeClassName="side-nav__content--active"
                    to={'/admin/hotel_combined_databases'}
                  >
                    {t('nav.hotelCombinedDatabases')}
                  </NavLink>
                  <NavLink
                    className="side-nav__content--links"
                    activeClassName="side-nav__content--active"
                    to={'/admin/human_verified_hotels'}
                  >
                    {t('nav.humanVerifiedHotels')}
                  </NavLink>
                  <NavLink
                    className="side-nav__content--links"
                    activeClassName="side-nav__content--active"
                    to={'/admin/human_verified_hotels_unmatched'}
                  >
                    {t('nav.unmatchedHumanVerifiedHotels')}
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-11 offset-lg-1 col-sm-10 offset-sm-2 col-md-10 offset-md-2 offset-xs-2 col-xs-10 App-body__content">
                <Switch>
                  <Route exact path="/(|admin)" component={Dashboard} />
                  <Route exact path="/admin/countries" component={CountryList} />
                  <Route
                    exact
                    path="/admin/countries/new"
                    render={props => <CountryForm {...props} />}
                  />
                  <Route exact path="/admin/countries/:id" component={Country} />
                  <Route
                    exact
                    path="/admin/countries/:id/edit"
                    render={props => <CountryForm {...props} />}
                  />
                  <Route exact path="/admin/continents" component={ContinentList} />
                  <Route
                    exact
                    path="/admin/continents/new"
                    render={props => <ContinentForm {...props} />}
                  />
                  <Route exact path="/admin/continents/:id" component={Continent} />
                  <Route
                    exact
                    path="/admin/continents/:id/edit"
                    render={props => <ContinentForm {...props} />}
                  />
                  <Route exact path="/admin/static_messages" component={StaticMessageList} />
                  <Route
                    exact
                    path="/admin/static_messages/new"
                    render={props => <StaticMessageForm {...props} />}
                  />
                  <Route exact path="/admin/static_messages/:id" component={StaticMessage} />
                  <Route
                    exact
                    path="/admin/static_messages/:id/edit"
                    render={props => <StaticMessageForm {...props} />}
                  />
                  <Route exact path="/admin/airports" component={AirportList} />
                  <Route
                    exact
                    path="/admin/airports/new"
                    render={props => <AirportForm {...props} />}
                  />
                  <Route exact path="/admin/airports/:id" component={Airport} />
                  <Route
                    exact
                    path="/admin/airports/:id/edit"
                    render={props => <AirportForm {...props} />}
                  />
                  <Route exact path="/admin/ancillary_services" component={AncillaryServiceList} />
                  <Route
                    exact
                    path="/admin/ancillary_services/new"
                    render={props => <AncillaryServiceForm {...props} />}
                  />
                  <Route exact path="/admin/ancillary_services/:id" component={AncillaryService} />
                  <Route
                    exact
                    path="/admin/ancillary_services/:id/edit"
                    render={props => <AncillaryServiceForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/ancillary_categories"
                    component={AncillaryCategoryList}
                  />
                  <Route
                    exact
                    path="/admin/ancillary_categories/new"
                    render={props => <AncillaryCategoryForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/ancillary_categories/:id"
                    component={AncillaryCategory}
                  />
                  <Route
                    exact
                    path="/admin/ancillary_categories/:id/edit"
                    render={props => <AncillaryCategoryForm {...props} />}
                  />
                  <Route exact path="/admin/airlines" component={AirlineList} />
                  <Route
                    exact
                    path="/admin/airlines/new"
                    render={props => <AirlineForm {...props} />}
                  />
                  <Route exact path="/admin/airlines/:id" component={Airline} />
                  <Route
                    exact
                    path="/admin/airlines/:id/edit"
                    render={props => <AirlineForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/airlines/:airline_id/fare_brands/new"
                    render={props => <FareBrandForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/airlines/:airline_id/unverified_fare_brands/:unverified_fare_brand_id/new"
                    render={props => <FareBrandForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/airlines/:airline_id/fare_brands/:id"
                    component={FareBrand}
                  />
                  <Route
                    exact
                    path="/admin/airlines/:airline_id/fare_brands/:id/edit"
                    render={props => <FareBrandForm {...props} />}
                  />
                  <Route exact path="/admin/features" component={FeatureList} />
                  <Route
                    exact
                    path="/admin/features/new"
                    render={props => <FeatureForm {...props} />}
                  />
                  <Route exact path="/admin/features/:id" component={Feature} />
                  <Route
                    exact
                    path="/admin/features/:id/edit"
                    render={props => <FeatureForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/car_rental_companies"
                    component={CarRentalCompanyList}
                  />
                  <Route
                    exact
                    path="/admin/car_rental_companies/new"
                    render={props => <CarRentalCompanyForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/car_rental_companies/:id"
                    component={CarRentalCompany}
                  />
                  <Route
                    exact
                    path="/admin/car_rental_companies/:id/edit"
                    render={props => <CarRentalCompanyForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/places/new"
                    render={props => <PlaceForm {...props} />}
                  />
                  <Route exact path="/admin/places/:id" component={Place} />
                  <Route
                    exact
                    path="/admin/places/:id/edit"
                    render={props => <PlaceForm {...props} />}
                  />
                  <Route exact path="/admin/banners" component={BannerList} />
                  <Route
                    exact
                    path="/admin/banners/new"
                    render={props => <BannerForm {...props} />}
                  />
                  <Route exact path="/admin/banners/:id" component={Banner} />
                  <Route
                    exact
                    path="/admin/banners/:id/edit"
                    render={props => <BannerForm {...props} />}
                  />

                  <Route exact path="/admin/cities" component={CityList} />
                  <Route exact path="/admin/cities/new" render={props => <CityForm {...props} />} />
                  <Route exact path="/admin/cities/:id" component={City} />
                  <Route
                    exact
                    path="/admin/cities/:id/edit"
                    render={props => <CityForm {...props} />}
                  />
                  <Route exact path="/admin/place_synonyms" component={PlaceSynonymList} />
                  <Route
                    exact
                    path="/admin/place_synonyms/new"
                    render={props => <PlaceSynonymForm {...props} />}
                  />
                  <Route exact path="/admin/place_synonyms/:id" component={PlaceSynonym} />
                  <Route
                    exact
                    path="/admin/place_synonyms/:id/edit"
                    render={props => <PlaceSynonymForm {...props} />}
                  />
                  <Route exact path="/admin/flights" component={FlightList} />
                  <Route
                    exact
                    path="/admin/flights/new"
                    render={props => <FlightForm {...props} />}
                  />
                  <Route exact path="/admin/flights/:id" component={Flight} />
                  <Route
                    exact
                    path="/admin/flights/:id/edit"
                    render={props => <FlightForm {...props} />}
                  />
                  <Route exact path="/admin/aircraft_types" component={AircraftTypeList} />
                  <Route
                    exact
                    path="/admin/aircraft_types/new"
                    render={props => <AircraftTypeForm {...props} />}
                  />
                  <Route exact path="/admin/aircraft_types/:id" component={AircraftType} />
                  <Route
                    exact
                    path="/admin/aircraft_types/:id/edit"
                    render={props => <AircraftTypeForm {...props} />}
                  />
                  <Route exact path="/admin/glossaries" component={GlossaryList} />
                  <Route
                    exact
                    path="/admin/glossaries/new"
                    render={props => <GlossaryForm {...props} />}
                  />
                  <Route exact path="/admin/glossaries/:id" component={Glossary} />
                  <Route
                    exact
                    path="/admin/glossaries/:id/edit"
                    render={props => <GlossaryForm {...props} />}
                  />
                  <Route exact path="/admin/fare_brand_verification" component={FareBrandVerificationList} />
                  <Route
                    exact
                    path="/admin/faqs"
                    render={props => <FaqList {...props} userId={data.currentUser.id} />}
                  />
                  <Route
                    exact
                    path="/admin/faqs/new"
                    render={props => <FaqForm {...props} userId={data.currentUser.id} />}
                  />
                  <Route exact path="/admin/faqs/:id" component={Faq} />
                  <Route
                    exact
                    path="/admin/faqs/:id/edit"
                    render={props => <FaqForm {...props} userId={data.currentUser.id} />}
                  />
                  <Route
                    exact
                    path="/admin/faq_labels/new"
                    render={props => <FaqLabelForm {...props} userId={data.currentUser.id} />}
                  />
                  <Route
                    exact
                    path="/admin/platform_features"
                    render={props => (
                      <PlatformFeatureList {...props} userId={data.currentUser.id} />
                    )}
                  />
                  <Route
                    exact
                    path="/admin/platform_features/new"
                    render={props => (
                      <PlatformFeatureForm {...props} userId={data.currentUser.id} />
                    )}
                  />
                  <Route exact path="/admin/platform_features/:id" component={PlatformFeature} />
                  <Route
                    exact
                    path="/admin/platform_features/:id/edit"
                    render={props => (
                      <PlatformFeatureForm {...props} userId={data.currentUser.id} />
                    )}
                  />
                  <Route
                    exact
                    path="/admin/platform_feature_labels/new"
                    render={props => (
                      <PlatformFeatureLabelForm {...props} userId={data.currentUser.id} />
                    )}
                  />
                  <Route exact path="/admin/welltravel_news" component={WelltravelNewsList} />
                  <Route
                    exact
                    path="/admin/welltravel_news/new"
                    render={props => <WelltravelNewsForm {...props} />}
                  />
                  <Route exact path="/admin/welltravel_news/:id" component={WelltravelNews} />
                  <Route
                    exact
                    path="/admin/welltravel_news/:id/edit"
                    render={props => <WelltravelNewsForm {...props} />}
                  />
                  <Route exact path="/admin/travel_news" component={TravelNewsList} />
                  <Route
                    exact
                    path="/admin/travel_news/new"
                    render={props => <TravelNewsForm {...props} />}
                  />
                  <Route exact path="/admin/travel_news/:id" component={TravelNews} />
                  <Route
                    exact
                    path="/admin/travel_news/:id/edit"
                    render={props => <TravelNewsForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/visa_procurement_categories"
                    component={VisaProcurementCategoryList}
                  />
                  <Route
                    exact
                    path="/admin/visa_procurement_categories/:id"
                    component={VisaProcurementCategory}
                  />
                  <Route
                    exact
                    path="/admin/visa_procurement_categories/:id/edit"
                    render={props => <VisaProcurementCategoryForm {...props} />}
                  />
                  <Route exact path="/admin/raw_hotels" component={RawHotelList} />
                  <Route exact path="/admin/raw_hotels/:id" component={RawHotel} />
                  <Route
                    exact
                    path="/admin/raw_hotels/:id/edit"
                    render={props => <RawHotelForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/hotel_combined_databases"
                    component={HotelCombinedDatabaseList}
                  />
                  <Route
                    exact
                    path="/admin/hotel_combined_databases/:id"
                    component={HotelCombinedDatabase}
                  />
                  <Route
                    exact
                    path="/admin/hotel_combined_databases/:id/edit"
                    render={props => <HotelCombinedDatabaseForm {...props} />}
                  />
                  <Route
                    exact
                    path="/admin/human_verified_hotels"
                    component={HumanVerifiableHotels}
                  />
                  <Route
                    exact
                    path="/admin/human_verified_hotels_unmatched"
                    component={UnmatchedHumanVerifiableHotels}
                  />
                  <Route
                    exact
                    path="/admin/place_tags"
                    render={() => <ComingSoon modelName="PlaceTag" title="placeTags" />}
                  />
                  <Route
                    exact
                    path="/admin/tax_categories"
                    render={() => <ComingSoon modelName="TaxCategory" title="taxCategories" />}
                  />
                  <Route
                    exact
                    path="/admin/visa_requirements"
                    render={() => (
                      <ComingSoon modelName="VisaRequirement" title="visaRequirements" />
                    )}
                  />
                </Switch>
              </div>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
};

const AppWithApolloProvider = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

export default AppWithApolloProvider;
