import React, { useState } from 'react';
import Button from '@wtag/rcl-button';
import { Link } from 'react-router-dom';
import Icon from '@wtag/rcl-icon';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { DELETE_FARE_BRAND } from '../../graphql/mutations/fareBrand';

const AirlineTableRow = props => {
  const { t } = useTranslation();
  const { data, resetAirlines, listAttributes, onDelete } = props;

  const [expand, setExpand] = useState(false);
  const [deleteFareBrand] = useMutation(DELETE_FARE_BRAND);

  const onFareBrandDelete = id => {
    window.confirm(t('fareBrands.index.confirmDelete')) &&
      deleteFareBrand({
        variables: { id },
      }).then(() => resetAirlines());
  };

  const renderValue = (item, value) => {
    if (item === 'fareBrands') {
      return (
        <div
          className="airline-list__data-value airline-list__data-value-clickable"
          key={item}
          role="presentation"
          onClick={() => setExpand(prevState => !prevState)}
        >
          <div>{value.length}</div>
          <div className="airline-list__data-value-clickable-icon">
            {expand ? <Icon name="iconUpChevron" /> : <Icon name="iconDownChevron" />}
          </div>
        </div>
      );
    }

    return (
      <div className="airline-list__data-value" key={item}>
        <div key={item}>{typeof value === 'boolean' ? t(`shared.${value.toString()}`) : value}</div>
      </div>
    );
  };

  return (
    <React.Fragment key={data.id}>
      <div className="airline-list__data">
        {listAttributes.map(item => renderValue(item, data[item]))}
        <div className="airline-list__data-value airline-list__data-value--actions">
          <Link to={`/admin/airlines/${data.id}`}>
            <Button label={t('shared.show')} />
          </Link>
          <Link to={`/admin/airlines/${data.id}/edit`}>
            <Button label={t('shared.edit')} />
          </Link>
          <Button label={t('shared.delete')} onClick={() => onDelete(data.id)} />
        </div>
      </div>
      {expand && (
        <React.Fragment>
          {data.fareBrands.map(fareBrand => (
            <div className="airline-list__data airline-list__data--nested" key={fareBrand.id}>
              <div className="airline-list__data-value">
                <div>{fareBrand.name}</div>
              </div>
              <div className="airline-list__data-value">
                <div>{t(`fareBrands.attributes.${fareBrand.cabinClass.name}`)}</div>
              </div>
              <div className="airline-list__data-value">
                <div>{fareBrand.bookingClass.join(', ')}</div>
              </div>
              <div className="airline-list__data-value airline-list__data-value--nested-actions">
                <Link to={`/admin/airlines/${data.id}/fare_brands/${fareBrand.id}`}>
                  <Button label={t('shared.show')} />
                </Link>
                <Link to={`/admin/airlines/${data.id}/fare_brands/${fareBrand.id}/edit`}>
                  <Button label={t('shared.edit')} />
                </Link>
                <Button
                  label={t('shared.delete')}
                  onClick={() => onFareBrandDelete(fareBrand.id)}
                />
              </div>
            </div>
          ))}
          <div className="airline-list__data airline-list__data--nested" key={data.id}>
            <Link to={`/admin/airlines/${data.id}/fare_brands/new`}>
              <Button label={t('shared.addNew')} />
            </Link>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

AirlineTableRow.propTypes = {
  data: PropTypes.object,
  currentCursor: PropTypes.string,
  searchParam: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  resetAirlines: PropTypes.func.isRequired,
  listAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AirlineTableRow;
