import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { GET_GOOGLE_MAPS_PUBlIC_API_KEY } from '../../graphql/queries/google_maps_public_api_key';
import toSnakeCase from '../../helpers/toSnakeCase';

import WrappedMap from '../common/WrappedMap';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import HotelDescription from '../common/HotelDescription';

const HotelInformationCard = props => {
  const { t } = useTranslation();
  const { data: mapKey } = useQuery(GET_GOOGLE_MAPS_PUBlIC_API_KEY);
  const renderMap = mapKey && mapKey.googleMapsPublicApiKey;

  let address = '';
  let phoneNumber = '';
  const providedAddress = props.additionalContents.address;

  if (providedAddress) {
    const street = providedAddress.street ? `${providedAddress.street}, ` : '';
    const city = providedAddress.city ? `${providedAddress.city}, ` : '';
    const district = providedAddress.district ? `${providedAddress.district}, ` : '';
    const country = providedAddress.country ? `${providedAddress.country}` : '';
    phoneNumber = providedAddress.phoneNumber ? `${providedAddress.phoneNumber}` : '';
    phoneNumber = providedAddress.telephone ? `${providedAddress.telephone}` : '';
    address = street + city + district + country;
  }

  return (
    <div className="hotel-information">
      <div className="hotel-information__header">
        <h2>{props.name ? props.name : t('shared.noInformation')} </h2>
        {props.edit && (
          <Link to={`/admin/${toSnakeCase(props.itemType)}/${props.id}/edit`}>
            <Button label={t('shared.edit')} />
          </Link>
        )}
      </div>

      <div className="hotel-information__field">
        <div className="hotel-information__field-attribute">
          {t(`${props.itemType}.attributes.address`)}:
        </div>
        {address.length > 0 ? address : t('shared.noInformation')}
      </div>

      <div className="hotel-information__field">
        <div className="hotel-information__field-attribute">
          {t(`${props.itemType}.attributes.description`)}:
        </div>
        {props.description ? (
          <HotelDescription hotelDescription={props.description} id={props.id} />
        ) : (
          t('shared.noInformation')
        )}
      </div>
      {props.detailedInformation && (
        <div className="hotel-information__field">
          <div className="hotel-information__field-attribute">
            {t(`${props.itemType}.attributes.phoneNumber`)}:
          </div>
          {phoneNumber.length > 0 ? phoneNumber : t('shared.noInformation')}
        </div>
      )}

      {props.detailedInformation && (
        <div className="hotel-information__field">
          <div className="hotel-information__field-attribute">
            {t(`${props.itemType}.attributes.supplierCodes`)}:
          </div>
          {props.supplierCodes
            ? JSON.stringify(props.supplierCodes, undefined, 4)
            : t('shared.noInformation')}
        </div>
      )}

      <div className="hotel-information__field">
        <div className="hotel-information__field-attribute">
          {t(`${props.itemType}.attributes.iso31661`)}:
        </div>
        {props.iso31661 ? props.iso31661 : t('shared.noInformation')}
      </div>

      {props.detailedInformation && (
        <div className="hotel-information__field">
          <div className="hotel-information__field-attribute">
            {t(`${props.itemType}.attributes.iso31662`)}:
          </div>
          {props.iso31662 ? props.iso31662 : t('shared.noInformation')}
        </div>
      )}

      {props.welltravelCode && (
        <div className="hotel-information__field">
          <div className="hotel-information__field-attribute">
            {t(`${props.itemType}.attributes.welltravelCode`)}:
          </div>
          {props.welltravelCode}
        </div>
      )}

      {props.welltravelCodes.length > 0 && (
        <div className="hotel-information__field">
          <div className="hotel-information__field-attribute">
            {t(`${props.itemType}.attributes.welltravelCodes`)}:
          </div>
          {props.welltravelCodes.join(', ')}
        </div>
      )}
      <div className="hotel-information__field">
        <div className="hotel-information__field-attribute">
          {t(`${props.itemType}.attributes.latitude`)}:
        </div>
        {props.latitude}&#176;,&nbsp;
        <div className="hotel-information__field-attribute">
          {t(`${props.itemType}.attributes.longitude`)}:
        </div>
        {props.longitude}&#176;
      </div>

      <div className="hotel-information__map">
        {renderMap && (
          <WrappedMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${mapKey.googleMapsPublicApiKey}`}
            latLong={{ lat: props.latitude, lng: props.longitude }}
            infoWindowContent={props.name}
          />
        )}
      </div>
    </div>
  );
};

HotelInformationCard.defaultProps = {
  welltravelCode: '',
  welltravelCodes: [],
  detailedInformation: true,
  edit: false,
};

HotelInformationCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  additionalContents: PropTypes.object,
  supplierCodes: PropTypes.object,
  iso31661: PropTypes.string,
  iso31662: PropTypes.string,
  welltravelCode: PropTypes.string,
  welltravelCodes: PropTypes.arrayOf(PropTypes.string),
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  description: PropTypes.string,
  detailedInformation: PropTypes.bool,
  itemType: PropTypes.string.isRequired,
  edit: PropTypes.bool,
};

export default HotelInformationCard;
