import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Icon from '@wtag/rcl-icon';
import Input from '@wtag/rcl-input';
import { debounce } from 'lodash';
import classNames from 'classnames';

import { itemsPerPage, maxPageNumberForFullLoad } from '../../variables';
import Header from '../common/Header';
import Paginate from '../common/Paginate';
import EmptyContentPlaceholder from '../common/EmptyContentPlaceholder';
import IconButton from '../rclComponents/IconButton';
import MetaIcon from '../icon/Icon';
import { GET_AIRLINES, GET_CURSORS_FOR_AIRLINES } from '../../graphql/queries/airline';
import { DELETE_AIRLINE } from '../../graphql/mutations/airline';
import GET_COUNT_OF_TOTAL_CURSORS from '../../graphql/queries/totalCursorsCount';
import SpinnerIcon from '../../assets/icons/spinner.svg';
import AirlineTableRow from './AirlineTableRow';
import isEntityAvailable from '../../helpers/isEntityAvailable';

const AirlineList = () => {
  const { t } = useTranslation();

  const [cursorState, setCursorState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(3);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [searchParam, setSearchParam] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [cursors, setCursors] = useState([]);
  const [cursorFetchNumber, setCursorFetchNumber] = useState(itemsPerPage * 4);
  const [requiredLengthToSearch, setRequiredLengthToSearch] = useState(2);
  const [sortAttribute, setSortAttribute] = useState('');
  const [sortAttributeDirection, setSortAttributeDirection] = useState('');

  const [deleteItem] = useMutation(DELETE_AIRLINE);
  const cursorData = useQuery(GET_CURSORS_FOR_AIRLINES, {
    variables: {
      first: cursorFetchNumber,
      after: currentCursor,
      query: searchParam,
      currentSortAttribute: sortAttribute,
      currentSortAttributeDirection: sortAttributeDirection,
    },
    fetchPolicy: 'network-only',
  });

  const cursorCountData = useQuery(GET_COUNT_OF_TOTAL_CURSORS, {
    variables: {
      modelName: 'airlines',
      query: searchParam,
    },
  });

  const { client, loading, data } = useQuery(GET_AIRLINES, {
    variables: {
      first: itemsPerPage,
      after: currentCursor,
      query: searchParam,
      currentSortAttribute: sortAttribute,
      currentSortAttributeDirection: sortAttributeDirection,
    },
    fetchPolicy: 'network-only',
  });

  const callSearchParam = useRef(
    debounce(async inputParam => {
      setSearchParam(inputParam);
    }, 1500),
  );

  const resetSearch = () => {
    setSearchParam('');
    setSearchValue('');
  };

  const postIcon = () => {
    if (loading) {
      return <img className="airline-list__search-post-icon" src={SpinnerIcon} alt="spinner" />;
    }
    return (
      <IconButton
        size="large"
        className="airline-list__search-post-icon"
        isIconOnly={true}
        icon={<MetaIcon name="close" />}
        onClick={resetSearch}
      />
    );
  };

  const forcePage = currentPage - 1;

  const listAttributes = ['name', 'fareBrands', 'code', 'icaoCode', 'deactivated', 'lastUpdatedAt'];

  const changePage = useCallback(value => {
    setCurrentPage(value.selected + 1);
    const cursorIndexFromCurrentPage = value.selected * itemsPerPage - 1;
    if (cursorIndexFromCurrentPage < 0) {
      setCurrentCursor(null);
    } else {
      setCurrentCursor(cursors[cursorIndexFromCurrentPage]);
    }
  }, [cursors]);

  useEffect(() => {
    if (cursorCountData && cursorCountData.data && !cursorCountData.loading) {
      const count = cursorCountData.data.totalCursorsCount / itemsPerPage;
      const pageCount = Math.ceil(count);
      setRequiredLengthToSearch(2);
      if (pageCount <= maxPageNumberForFullLoad) {
        setCursorFetchNumber(null);
      } else {
        setCursorFetchNumber(itemsPerPage * 4);
      }
      if (pageCount < currentPage) {
        changePage({ selected: 0 });
      }
      setPageCount(pageCount);
    }
    if (cursorData && cursorData.data && !cursorData.loading && !cursorState) {
      setCursorState(true);
      const oldCursors = Object.assign([], cursors);
      const newCursors = cursorData.data['airlines'].edges.map(edge => edge.cursor);
      const finalCursors = oldCursors.concat(
        newCursors.filter(newCursor => oldCursors.indexOf(newCursor) < 0),
      );
      setCursors(finalCursors);
    }
  }, [cursorCountData, cursorData, changePage, currentPage, cursors, cursorState]);

  const resetAirlines = () => client.resetStore();

  const onDelete = id => {
    window.confirm(t('airlines.index.confirmDelete')) &&
      deleteItem({
        variables: { id },
      }).then(() => resetAirlines());
  };

  const onSearchParamChange = value => {
    setSearchValue(value);
    if (value === '' || value.length >= requiredLengthToSearch) {
      callSearchParam.current(value);
    }
  };

  const setSortingColumn = item => {
    setSortAttribute(item);
    if (sortAttributeDirection === 'down') {
      setSortAttributeDirection('up');
    } else {
      setSortAttributeDirection('down');
    }
  };

  const isColumnSelected = (item, direction) =>
    sortAttribute === item && sortAttributeDirection && sortAttributeDirection === direction;

  const headerWithActions = item => (
    <div
      className="airline-list__value airline-list__value-clickable airline-list__value-title"
      key={item}
      role="presentation"
      onClick={() => setSortingColumn(item)}
    >
      <p>{t(`airlines.attributes.${item}`)}</p>

      <div
        className={classNames('airline-list__value-clickable-icon', {
          'airline-list__value-clickable-icon--active': isColumnSelected(item, 'up'),
        })}
      >
        <Icon name="arrowUp" />
      </div>
      <div
        className={classNames('airline-list__value-clickable-icon', {
          'airline-list__value-clickable-icon--active': isColumnSelected(item, 'down'),
        })}
      >
        <Icon name="arrowDown" />
      </div>
    </div>
  );

  return (
    <div className="airline-list">
      <Header title="nav.airlines" linkTo="/admin/airlines/new" buttonLabel="shared.addNew" />
      <div>
        <Input
          label={t('list.search.label')}
          value={searchValue}
          onChange={onSearchParamChange}
          placeholder={t('airlines.index.searchPlaceholder')}
          postIcon={postIcon()}
        />
      </div>
      <div>
        {loading && <div className="lds-dual-ring" />}
        {!loading && (
          <React.Fragment>
            {isEntityAvailable(data, 'airlines') ? (
              <>
                <div className="airline-list__pagination">
                  <Paginate
                    pageCount={pageCount}
                    onPageChange={changePage}
                    forcePage={forcePage}
                    marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                  />
                </div>
                <div className="airline-list__header">
                  {listAttributes.map(item => headerWithActions(item))}
                  <div className="airline-list__value airline-list__value--actions">
                    <p>{t('list.header.actions')}</p>
                  </div>
                </div>
                <div className="airline-list__table">
                  {data['airlines'].edges.map(data => (
                    <AirlineTableRow
                      data={data.node}
                      onDelete={onDelete}
                      key={data.node.id}
                      listAttributes={listAttributes}
                      resetAirlines={resetAirlines}
                    />
                  ))}
                </div>
                <div className="airline-list__pagination">
                  <Paginate
                    pageCount={pageCount}
                    onPageChange={changePage}
                    forcePage={forcePage}
                    marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                  />
                </div>
              </>
            ) : (
                <EmptyContentPlaceholder entityName={t('nav.airlines').toLowerCase()} modelName={'Airline'} />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default AirlineList;
