import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import MultiSelectBox from '../rclComponents/selectBox';
import TextEditor from '../common/TextEditor';

import { GET_WELLTRAVEL_NEWS_ONE, GET_WELLTRAVEL_NEWS_PLATFORMS } from '../../graphql/queries/welltravelNews';

import { GET_HASHTAGS } from '../../graphql/queries/hashtag';
import { CREATE_WELLTRAVEL_NEWS, EDIT_WELLTRAVEL_NEWS } from '../../graphql/mutations/welltravelNews';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getWelltravelNewsOne } from '../../graphql/queries/welltravelNews';

import clearCache from '../../helpers/clearCache';
import returnEmptyStringWhenNull from '../../helpers/returnEmptyStringWhenNull';
import imageUrl from '../../helpers/imageUrl';
import FileUpload from '../common/FileUpload';
import NoResultsFound from '../common/NoResultsFound';
import { debounce } from 'lodash';

const WelltravelNewsForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createWelltravelNews, callbackDataOnCreate] = useMutation(CREATE_WELLTRAVEL_NEWS, {
    update: cache => clearCache(cache, /WelltravelNews/),
  });

  const [editWelltravelNews, callbackDataOnEdit] = useMutation(EDIT_WELLTRAVEL_NEWS);

  const welltravelNewsFromId = useQuery(GET_WELLTRAVEL_NEWS_ONE, {
    variables: { id },
    skip: isNewRecord,
  });

  const { loading: hashtagListLoading, data: hashtagList } = useQuery(GET_HASHTAGS);

  const [title, setTitle] = useState('');
  const [teaser, setTeaser] = useState('');
  const [externalLink, setExternalLink] = useState('');
  const [platform, setPlatform] = useState('');
  const [content, setContent] = useState('');
  const [hashtags, setHashtags] = useState([]);
  const [image, setImage] = useState(null);
  const [previousImage, setPreviousImage] = useState(null);

  const { loading: platformListLoading, data: platformListData } = useQuery(
    GET_WELLTRAVEL_NEWS_PLATFORMS,
  );

  const onTitleChange = value => {
    setTitle(value);
  };

  const onTeaserChange = value => {
    setTeaser(value);
  };

  const onExternalLinkChange = value => {
    setExternalLink(value);
  };

  const onPlatformChange = selectedOption => {
    setPlatform(selectedOption.value);
  };

  const onHashtagsChange = value => {
    setHashtags(value);
  };

  const onContentChange = value => {
    setContent(value);
  };

  const onImageFileUpload = files => {
    setImage(files[0]);
  };

  useEffect(() => {
    if (welltravelNewsFromId && welltravelNewsFromId.data && !welltravelNewsFromId.loading) {
      const welltravelNews = welltravelNewsFromId.data.welltravelNewsOne;
      setTitle(welltravelNews.title);
      setTeaser(welltravelNews.teaser);
      setExternalLink(welltravelNews.externalLink);
      setPlatform(returnEmptyStringWhenNull(welltravelNews.platform));
      setHashtags(
        welltravelNews.hashtags.map(hashtag => ({
          value: hashtag.id,
          label: hashtag.name,
        })),
      );
      setContent(welltravelNews.content);
      setPreviousImage(welltravelNews.imageUrls.large);
    }
  }, [welltravelNewsFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();
    const hashtagIds = hashtags && hashtags.map(hashtag => hashtag.value);
    if (isNewRecord) {
      createWelltravelNews({
        variables: { title, teaser, externalLink, platform, hashtagIds, content, image, },
      })
        .then(({ data }) => {
          props.history.push(`/admin/welltravel_news/${data.createWelltravelNews.id}`);
        })
        .catch(() => props.onError());
    } else {
      editWelltravelNews({
        variables: { id, title, teaser, externalLink, platform, hashtagIds, content, image, },
        refetchQueries: [getWelltravelNewsOne(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/welltravel_news/${data.editWelltravelNews.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  let hashtagOptions = [];
  if (!hashtagListLoading) {
    hashtagOptions = hashtagList.hashtags.edges.map(hashtag => ({
      value: hashtag.node.id,
      label: hashtag.node.name,
    }));
  }

  let platformOptions = [];
  if (!platformListLoading) {
    platformOptions = platformListData.welltravelNewsPlatforms.map(platform => ({
      value: platform.value,
      label: t(`categories.${platform.value}`),
    }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('welltravelNews.show.title') })
          : t('shared.editTitle', { entityName: t('welltravelNews.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
              <div className="common-form__field welltravel_news-form__title">
                <Input
                  label={t('welltravelNews.attributes.title')}
                  value={title}
                  onChange={onTitleChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field welltravel_news-form__teaser">
                <Input
                  label={t('welltravelNews.attributes.teaser')}
                  value={teaser}
                  onChange={onTeaserChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field welltravel_news-form__external_link">
                <Input
                  label={t('welltravelNews.attributes.externalLink')}
                  value={externalLink}
                  onChange={onExternalLinkChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field welltravel_news-form__hashtags">
                <MultiSelectBox
                  isMulti={true}
                  label={t('welltravelNews.attributes.hashtags')}
                  value={hashtags}
                  options={hashtagOptions}
                  onChange={onHashtagsChange}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 col-bleed-y">
              <div className="common-form__field welltravel_news-form__platform">
                <SelectBox
                  label={t('welltravelNews.attributes.platform')}
                  value={platformOptions.find(option => option.value === platform)}
                  options={platformOptions}
                  onChange={onPlatformChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
              <div className="common-form__field welltravel_news-form__content">
                <h2>{t('welltravelNews.attributes.content')}</h2>
                <TextEditor customModule={true} value={content} onChange={onContentChange} />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
          <div className="col-4 col-bleed-y">
            <h2>{t('welltravelNews.attributes.image')}</h2>
            {previousImage && (
              <div className="common-form__previous-image">
                <img src={imageUrl(previousImage)} alt="preview" />
              </div>
            )}
            <FileUpload onFileUpload={onImageFileUpload} multiple={false} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(WelltravelNewsForm);
