import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import { Textarea } from '@wtag/react-comp-lib';
import WrappedMap from '../common/WrappedMap';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_HOTEL_COMBINED_DATABASE,
  getHotelCombinedDatabase,
} from '../../graphql/queries/hotelCombinedDatabase';
import { GET_GOOGLE_MAPS_PUBlIC_API_KEY } from '../../graphql/queries/google_maps_public_api_key';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { EDIT_HOTEL_COMBINED_DATABASE } from '../../graphql/mutations/hotelCombinedDatabase';
import { useTranslation } from 'react-i18next';
import SelectBox from '@wtag/rcl-select-box';
import { debounce } from 'lodash';

const HotelCombinedDatabaseForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const { data } = useQuery(GET_GOOGLE_MAPS_PUBlIC_API_KEY);

  const [editHotelCombinedDatabase, callbackDataOnEdit] = useMutation(EDIT_HOTEL_COMBINED_DATABASE);
  const hotelCombinedDatabaseFromId = useQuery(GET_HOTEL_COMBINED_DATABASE, {
    variables: { id },
  });

  const [name, setName] = useState('');
  const [welltravelCodes, setWelltravelCodes] = useState('');
  const [alternateNames, setAlternateNames] = useState('');
  const [mainSource, setMainSource] = useState('');
  const [otherSources, setOtherSources] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [multipleSource, setMultipleSource] = useState(false);
  const [iso31661, setIso31661] = useState('');
  const [iso31662, setIso31662] = useState('');
  const [imageUrls, setImageUrls] = useState('');
  const [description, setDescription] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [additionalContents, setAdditionalContents] = useState('');
  const [supplierCodes, setSupplierCodes] = useState('');
  const [proximityPercentage, setProximityPercentage] = useState('');

  const onNameChange = value => {
    setName(value);
  };

  const onWelltravelCodesChange = event => {
    setWelltravelCodes(event.target.value);
  };

  const onAlternateNamesChange = event => {
    setAlternateNames(event.target.value);
  };

  const onMainSourceChange = value => {
    setMainSource(value);
  };

  const onOtherSourcesChange = event => {
    setOtherSources(event.target.value);
  };

  const onUniqueIdChange = value => {
    setUniqueId(value);
  };

  const onMultipleSourceChange = selectedOption => {
    setMultipleSource(selectedOption.value);
  };

  const onProximityPercentageChange = value => {
    setProximityPercentage(parseFloat(value));
  };

  const onIso31661Change = value => {
    setIso31661(value);
  };

  const onIso31662Change = value => {
    setIso31662(value);
  };

  const onImageUrlsChange = event => {
    setImageUrls(event.target.value);
  };

  const onDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const onAdditionalContentsChange = event => {
    setAdditionalContents(event.target.value);
  };

  const onSupplierCodesChange = event => {
    setSupplierCodes(event.target.value);
  };

  const setLatLong = value => {
    setLatitude(value.lat);
    setLongitude(value.lng);
  };

  useEffect(() => {
    if (
      hotelCombinedDatabaseFromId &&
      hotelCombinedDatabaseFromId.data &&
      !hotelCombinedDatabaseFromId.loading
    ) {
      const hotelCombinedDatabaseData = hotelCombinedDatabaseFromId.data.hotelCombinedDatabase;
      setName(hotelCombinedDatabaseData.name);
      setAlternateNames(JSON.stringify(hotelCombinedDatabaseData.alternateNames, undefined, 4));
      setMainSource(hotelCombinedDatabaseData.mainSource);
      setOtherSources(JSON.stringify(hotelCombinedDatabaseData.otherSources, undefined, 4));
      setMultipleSource(hotelCombinedDatabaseData.multipleSource);
      setUniqueId(hotelCombinedDatabaseData.uniqueId);
      setProximityPercentage(hotelCombinedDatabaseData.proximityPercentage);
      setWelltravelCodes(JSON.stringify(hotelCombinedDatabaseData.welltravelCodes, undefined, 4));
      setIso31661(hotelCombinedDatabaseData.iso31661);
      setIso31662(hotelCombinedDatabaseFromId.data.hotelCombinedDatabase.iso31662);
      setImageUrls(JSON.stringify(hotelCombinedDatabaseData.imageUrls, undefined, 4));
      setDescription(hotelCombinedDatabaseData.description);
      setLatitude(hotelCombinedDatabaseData.latitude);
      setLongitude(hotelCombinedDatabaseData.longitude);
      setSupplierCodes(JSON.stringify(hotelCombinedDatabaseData.supplierCodes, undefined, 4));
      setAdditionalContents(
        JSON.stringify(hotelCombinedDatabaseData.additionalContents, undefined, 4),
      );
    }
  }, [hotelCombinedDatabaseFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();
    editHotelCombinedDatabase({
      variables: {
        id,
        name,
        alternateNames,
        mainSource,
        otherSources,
        welltravelCodes,
        uniqueId,
        proximityPercentage,
        multipleSource,
        iso31661,
        iso31662,
        additionalContents,
        supplierCodes,
        latitude,
        longitude,
        imageUrls,
        description,
      },
      refetchQueries: [getHotelCombinedDatabase(id)],
    })
      .then(({ data }) => {
        props.history.push(`/admin/hotel_combined_databases/${data.editHotelCombinedDatabase.id}`);
      })
      .catch(() => props.onError());
  }, 500);

  const multipleSourceOptions = [
    { value: true, label: t('shared.true') },
    { value: false, label: t('shared.false') },
  ];

  const renderMap = data && data.googleMapsPublicApiKey && latitude && longitude;

  return (
    <div className="common-form">
      <div className="common-form__title">
        {t('shared.editTitle', { entityName: t('hotelCombinedDatabases.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-12">
              <div className="common-form__field hotel-combined-database-form__name">
                <Input
                  label={t('hotelCombinedDatabases.attributes.name')}
                  value={name}
                  onChange={onNameChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field hotel-combined-database-unique_id">
                <Input
                  label={t('hotelCombinedDatabases.attributes.uniqueId')}
                  value={uniqueId}
                  onChange={onUniqueIdChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__iso31661">
                <Input
                  label={t('hotelCombinedDatabases.attributes.iso31661')}
                  value={iso31661}
                  onChange={onIso31661Change}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__iso31662">
                <Input
                  label={t('hotelCombinedDatabases.attributes.iso31662')}
                  value={iso31662}
                  onChange={onIso31662Change}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__main_source">
                <Input
                  label={t('hotelCombinedDatabases.attributes.mainSource')}
                  value={mainSource}
                  onChange={onMainSourceChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__multiple_source">
                <SelectBox
                  label={t('hotelCombinedDatabases.attributes.multipleSource')}
                  value={multipleSourceOptions.find(option => option.value === multipleSource)}
                  options={multipleSourceOptions}
                  onChange={onMultipleSourceChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__proximity_percentage">
                <Input
                  label={t('hotelCombinedDatabases.attributes.proximityPercentage')}
                  value={proximityPercentage}
                  onChange={onProximityPercentageChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="common-form__field hotel-combined-database-form__description">
                <Textarea
                  label={t('hotelCombinedDatabases.attributes.description')}
                  value={description}
                  onChange={onDescriptionChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__image_urls">
                <Textarea
                  label={`${t('hotelCombinedDatabases.attributes.imageUrls')}(${t(
                    'hotelCombinedDatabases.hints.array',
                  )})`}
                  value={imageUrls}
                  onChange={onImageUrlsChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__alternate_names">
                <Textarea
                  label={`${t('hotelCombinedDatabases.attributes.alternateNames')}(${t(
                    'hotelCombinedDatabases.hints.array',
                  )})`}
                  value={alternateNames}
                  onChange={onAlternateNamesChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__welltravel_codes">
                <Textarea
                  label={`${t('hotelCombinedDatabases.attributes.welltravelCodes')}(${t(
                    'hotelCombinedDatabases.hints.array',
                  )})`}
                  value={welltravelCodes}
                  onChange={onWelltravelCodesChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__additional_contents">
                <Textarea
                  label={`${t('hotelCombinedDatabases.attributes.additionalContents')}(${t(
                    'hotelCombinedDatabases.hints.json',
                  )})`}
                  value={additionalContents}
                  onChange={onAdditionalContentsChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__supplier_codes">
                <Textarea
                  label={`${t('hotelCombinedDatabases.attributes.supplierCodes')}(${t(
                    'hotelCombinedDatabases.hints.warning',
                  )})`}
                  value={supplierCodes}
                  onChange={onSupplierCodesChange}
                  placeholder={''}
                />
              </div>
              <div className="common-form__field hotel-combined-database-form__other_sources">
                <Textarea
                  label={`${t('hotelCombinedDatabases.attributes.otherSources')}(${t(
                    'hotelCombinedDatabases.hints.array',
                  )})`}
                  value={otherSources}
                  onChange={onOtherSourcesChange}
                  placeholder={''}
                />
              </div>
            </div>
            <div className="common-form__submit-button">
              <Button label={t('shared.update')} onClick={submitToBackEnd} />
            </div>
          </div>
          <div className="col-6">
            <div className="common-form__map">
              {renderMap && (
                <WrappedMap
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${data.googleMapsPublicApiKey}`}
                  latLong={{ lat: latitude, lng: longitude }}
                  infoWindowContent={name}
                  getLatLong={value => {
                    setLatLong(value);
                  }}
                />
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="common-form__field hotel-combined-database-form__latitude">
                <Input
                  type="number"
                  label={t('hotelCombinedDatabases.attributes.latitude')}
                  value={latitude}
                  onChange={()=> latitude }
                  hint={t('hotelCombinedDatabases.hints.latLonField')}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="common-form__field hotel-combined-database-form__longitude">
                <Input
                  type="number"
                  label={t('hotelCombinedDatabases.attributes.longitude')}
                  value={longitude}
                  onChange={()=> longitude }
                  hint={t('hotelCombinedDatabases.hints.latLonField')}
                  isClearable={false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(HotelCombinedDatabaseForm);
