import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_FLIGHT } from '../../graphql/queries/flight';
import { GET_AIRLINES } from '../../graphql/queries/airline';
import { CREATE_FLIGHT, EDIT_FLIGHT } from '../../graphql/mutations/flight';
import { useTranslation } from 'react-i18next';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getFlight } from '../../graphql/queries/flight';
import clearCache from '../../helpers/clearCache';
import returnEmptyStringWhenNull from '../../helpers/returnEmptyStringWhenNull';
import NoResultsFound from '../common/NoResultsFound';
import { debounce } from 'lodash';

const FlightForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createFlight, callbackDataOnCreate] = useMutation(CREATE_FLIGHT, {
    update: cache => clearCache(cache, /Flight/),
  });
  const [editFlight, callbackDataOnEdit] = useMutation(EDIT_FLIGHT);
  const { loading: airlinesLoading, data: airlinesData } = useQuery(GET_AIRLINES);
  const flightFromId = useQuery(GET_FLIGHT, {
    variables: { id },
    skip: isNewRecord,
  });

  const [flightNumber, setFlightNumber] = useState('');
  const [onTimePercentage, setOnTimePercentage] = useState('');
  const [wifiAvailability, setWifiAvailability] = useState(null);
  const [airlineId, setAirlineId] = useState('');

  const onFlightNumberChange = value => {
    setFlightNumber(value);
  };

  const onTimePercentageChange = value => {
    setOnTimePercentage(value);
  };

  const onWifiAvailabilityChange = selectedOption => {
    setWifiAvailability(selectedOption.value);
  };

  const onAirlineIdChange = selectedOption => {
    setAirlineId(selectedOption.value);
  };

  useEffect(() => {
    if (flightFromId && flightFromId.data && !flightFromId.loading) {
      setFlightNumber(flightFromId.data.flight.flightNumber);
      setOnTimePercentage(returnEmptyStringWhenNull(flightFromId.data.flight.onTimePercentage));
      setWifiAvailability(flightFromId.data.flight.wifiAvailability);
      setAirlineId(flightFromId.data.flight.airline.id);
    }
  }, [flightFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createFlight({
        variables: { flightNumber, onTimePercentage, wifiAvailability, airlineId },
      })
        .then(({ data }) => {
          props.history.push(`/admin/flights/${data.createFlight.id}`);
        })
        .catch(() => props.onError());
    } else {
      editFlight({
        variables: { id, flightNumber, onTimePercentage, wifiAvailability, airlineId },
        refetchQueries: [getFlight(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/flights/${data.editFlight.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  const wifiAvailabilityOptions = [
    { value: true, label: t('shared.true') },
    { value: false, label: t('shared.false') },
  ];

  let airlineOptions;

  if (airlinesLoading) {
    airlineOptions = [];
  } else {
    airlineOptions = airlinesData.airlines.edges.map(airline => ({
      value: airline.node.id,
      label: airline.node.name,
    }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('flights.show.title') })
          : t('shared.editTitle', { entityName: t('flights.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field flight-form__flightNumber">
                <Input
                  label={t('flights.attributes.flightNumber')}
                  value={flightNumber}
                  onChange={onFlightNumberChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field flight-form__onTimePercentage">
                <Input
                  label={t('flights.attributes.onTimePercentage')}
                  value={onTimePercentage}
                  onChange={onTimePercentageChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field flight-form__airline">
                <SelectBox
                  label={t('flights.attributes.airline')}
                  value={airlineOptions.find(option => option.value === airlineId)}
                  options={airlineOptions}
                  onChange={onAirlineIdChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field flight-form__wifiAvailability">
                <SelectBox
                  label={t('flights.attributes.wifiAvailability')}
                  value={wifiAvailabilityOptions.find(option => option.value === wifiAvailability)}
                  options={wifiAvailabilityOptions}
                  onChange={onWifiAvailabilityChange}
                  isClearable={false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(FlightForm);
