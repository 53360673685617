import React, { useState, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { validate as uuidValidate } from 'uuid';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import { useTranslation } from 'react-i18next';
import Input from '@wtag/rcl-input';
import  SelectBox from '@wtag/rcl-select-box'
import { useQuery, useMutation } from '@apollo/react-hooks';
import Radio from '../rclComponents/Radio';
import MultiSelectBox from '../rclComponents/selectBox';
import { GET_AIRLINE } from '../../graphql/queries/airline';
import { GET_FARE_BRAND } from '../../graphql/queries/fareBrand';
import { CREATE_FARE_BRAND, EDIT_FARE_BRAND } from '../../graphql/mutations/fareBrand';
import { GET_UNVERIFIED_SUPPLIER_FARE_BRAND } from '../../graphql/queries/unverifiedSupplierFareBrand';

import clearCache from '../../helpers/clearCache';
import normalizeMultiFormAttributes from '../../helpers/normalizeMultiFormAttributes';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getFareBrand } from '../../graphql/queries/fareBrand';
import returnEmptyStringWhenNull from '../../helpers/returnEmptyStringWhenNull';
import { debounce } from 'lodash';
import SupplierBrandName from './SupplierBrandName';

const FareBrandForm = props => {
  const { t } = useTranslation();
  let { airline_id: airlineId, id, unverified_fare_brand_id: unverifiedFareBrandId } = useParams();
  const isNewRecord = !id;
  const disabledForUnverifiedFareBrand = !!unverifiedFareBrandId;

  const [createFareBrand, callbackDataOnCreate] = useMutation(CREATE_FARE_BRAND, {
    update: cache => clearCache(cache, /FareBrand/),
  });
  const [editFareBrand, callbackDataOnEdit] = useMutation(EDIT_FARE_BRAND);

  const airlineFromId = useQuery(GET_AIRLINE, {
    variables: { id: airlineId },
  });

  const fareBrandFromId = useQuery(GET_FARE_BRAND, {
    variables: { id },
    skip: isNewRecord,
  });

  const unverifiedSupplierFareBrandFromId = useQuery(GET_UNVERIFIED_SUPPLIER_FARE_BRAND, {
    variables: { id: unverifiedFareBrandId },
    skip: !unverifiedFareBrandId,
  });

  const [airlineName, setAirlineName] = useState('');
  const [airlineCode, setAirlineCode] = useState('');
  const [cabinClassOptions, setCabinClassOptions] = useState([]);

  // basic information
  const [name, setName] = useState('');
  const [cabinClassName, setCabinClassName] = useState(null);
  const [bookingClass, setBookingClass] = useState([]);
  const [refundable, setRefundable] = useState('no_refundable_information');
  const [cancelPenalty, setCancelPenalty] = useState(null);
  const [cancelPenaltyCurrency, setCancelPenaltyCurrency] = useState('');
  const [changeable, setChangeable] = useState('no_changeable_information');
  const [changePenalty, setChangePenalty] = useState(null);
  const [changePenaltyCurrency, setChangePenaltyCurrency] = useState('');
  const [noShow, setNoShow] = useState('no_no_show_information');
  const [noShowPenalty, setNoShowPenalty] = useState(null);
  const [noShowPenaltyCurrency, setNoShowPenaltyCurrency] = useState('');
  const [handLuggage, setHandLuggage] = useState('no_hand_luggage_information');
  const [handLuggageCount, setHandLuggageCount] = useState(null);
  const [handLuggageWeight, setHandLuggageWeight] = useState(null);
  const [fareBrandNameChange, setFareBrandNameChange] = useState(
    'no_fare_brand_name_change_information',
  );
  const [seatReservation, setSeatReservation] = useState('no_seat_reservation_information');
  const [co2Compensation, setCo2Compensation] = useState('no_co2_compensation_information');

  // airport facilities
  const [loungeAccess, setLoungeAccess] = useState('no_lounge_access_information');
  const [priorityBoarding, setPriorityBoarding] = useState('no_priority_boarding_information');
  const [priorityLuggage, setPriorityLuggage] = useState('no_priority_luggage_information');
  const [exclusiveCheckIn, setExclusiveCheckIn] = useState('no_exclusive_check_in_information');
  const [fastTrackSecurity, setFastTrackSecurity] = useState('no_fast_track_security_information');
  const [mileUpgrade, setMileUpgrade] = useState('no_mile_upgrade_information');

  // in-flight facilities
  const [catering, setCatering] = useState('no_catering_information');
  const [welcomeDrink, setWelcomeDrink] = useState('no_welcome_drink_information');
  const [digitalMagazines, setDigitalMagazines] = useState('no_digital_magazines_information');
  const [flatbed, setFlatbed] = useState('no_flatbed_information');

  // Loyalty Program
  const [milesAward, setMilesAward] = useState('no_miles_award_information');
  const [milesAwardAmount, setMilesAwardAmount] = useState(null);
  const [milesStatus, setMilesStatus] = useState('no_miles_status_information');
  const [milesStatusAmount, setMilesStatusAmount] = useState(null);

  const [supplierBrandNames, setSupplierBrandNames] = useState([]);

  const [hasSupplierProvidedNames, setHasSupplierProvidedNames] = useState(false);

  // this will generate A to Z alphabetical booking class options
  const bookingClassOptions = useMemo(
    () =>
      Array.from(Array(26)).map((e, i) => ({
        label: String.fromCharCode(i + 65),
        value: String.fromCharCode(i + 65),
      })),
    [],
  );

  useEffect(() => {
    if (airlineFromId && airlineFromId.data && !airlineFromId.loading) {
      const airline = airlineFromId.data.airline;
      setAirlineName(airline.name);
      setAirlineCode(airline.code);
      setCabinClassOptions(
        airline.cabinClasses.map(cabinClass => ({
          label: t(`fareBrands.attributes.${cabinClass.name}`),
          value: cabinClass.name,
        })),
      );
    }

    if (fareBrandFromId && fareBrandFromId.data && !fareBrandFromId.loading) {
      const fareBrand = fareBrandFromId.data.fareBrand;
      setName(fareBrand.name);
      setBookingClass(
        fareBrand.bookingClass.map(bookingClassName => ({
          value: bookingClassName,
          label: bookingClassName,
        })),
      );
      setCabinClassName(fareBrand.cabinClass.name);
      setCancelPenalty(fareBrand.cancelPenalty);
      setCancelPenaltyCurrency(returnEmptyStringWhenNull(fareBrand.cancelPenaltyCurrency));
      setCatering(fareBrand.catering);
      setChangePenalty(fareBrand.changePenalty);
      setChangePenaltyCurrency(returnEmptyStringWhenNull(fareBrand.changePenaltyCurrency));
      setChangeable(fareBrand.changeable);
      setCo2Compensation(fareBrand.co2Compensation);
      setDigitalMagazines(fareBrand.digitalMagazines);
      setExclusiveCheckIn(fareBrand.exclusiveCheckIn);
      setFastTrackSecurity(fareBrand.fastTrackSecurity);
      setFlatbed(fareBrand.flatbed);
      setHandLuggage(fareBrand.handLuggage);
      setHandLuggageCount(fareBrand.handLuggageCount);
      setHandLuggageWeight(fareBrand.handLuggageWeight);
      setLoungeAccess(fareBrand.loungeAccess);
      setMileUpgrade(fareBrand.mileUpgrade);
      setMilesAward(fareBrand.milesAward);
      setMilesAwardAmount(fareBrand.milesAwardAmount);
      setMilesStatus(fareBrand.milesStatus);
      setMilesStatusAmount(fareBrand.milesStatusAmount);
      setFareBrandNameChange(fareBrand.fareBrandNameChange);
      setNoShow(fareBrand.noShow);
      setNoShowPenalty(fareBrand.noShowPenalty);
      setNoShowPenaltyCurrency(returnEmptyStringWhenNull(fareBrand.noShowPenaltyCurrency));
      setPriorityBoarding(fareBrand.priorityBoarding);
      setPriorityLuggage(fareBrand.priorityLuggage);
      setRefundable(fareBrand.refundable);
      setSeatReservation(fareBrand.seatReservation);
      setWelcomeDrink(fareBrand.welcomeDrink);
      setSupplierBrandNames(fareBrand.supplierFareBrands);
      if (fareBrand.supplierFareBrands.length > 0) {
        setHasSupplierProvidedNames(true);
      }
    }

    if (
      unverifiedSupplierFareBrandFromId &&
      unverifiedSupplierFareBrandFromId.data &&
      !unverifiedSupplierFareBrandFromId.loading
    ) {
      const unverifiedFareBrand =
        unverifiedSupplierFareBrandFromId.data.unverifiedSupplierFareBrand;

      setCabinClassName(unverifiedFareBrand.cabinClass);
      setBookingClass([
        {
          label: unverifiedFareBrand.bookingClass,
          value: unverifiedFareBrand.bookingClass,
        },
      ]);
      setHasSupplierProvidedNames(true);
      setSupplierBrandNames([
        {
          id: uuidv4(),
          supplierBrandName: unverifiedFareBrand.brandName,
          supplierName: unverifiedFareBrand.supplierName,
        },
      ]);
    }
  }, [airlineFromId, fareBrandFromId, unverifiedSupplierFareBrandFromId]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      let fareBrandParams = {
        airlineId,
        name,
        cabinClassName,
        bookingClass: bookingClass.map(bookingClassObj => bookingClassObj.value),
        refundable,
        cancelPenalty: parseFloat(cancelPenalty),
        cancelPenaltyCurrency,
        changeable,
        changePenalty: parseFloat(changePenalty),
        changePenaltyCurrency,
        noShow,
        noShowPenalty: parseFloat(noShowPenalty),
        noShowPenaltyCurrency,
        handLuggage,
        handLuggageCount: parseInt(handLuggageCount),
        handLuggageWeight: parseFloat(handLuggageWeight),
        fareBrandNameChange,
        seatReservation,
        co2Compensation,
        loungeAccess,
        priorityBoarding,
        priorityLuggage,
        exclusiveCheckIn,
        fastTrackSecurity,
        mileUpgrade,
        catering,
        welcomeDrink,
        digitalMagazines,
        flatbed,
        milesAward,
        milesStatus,
        milesAwardAmount: parseFloat(milesAwardAmount),
        milesStatusAmount: parseFloat(milesStatusAmount),
        supplierFareBrandsAttributes: normalizeMultiFormAttributes(supplierBrandNames),
        unverifiedFareBrandId,
      };

      fareBrandParams = Object.keys(fareBrandParams)
        .filter(key => fareBrandParams[key] !== '')
        .reduce((obj, key) => {
          obj[key] = fareBrandParams[key];
          return obj;
        }, {});

      createFareBrand({
        variables: fareBrandParams,
      })
        .then(({ data }) => {
          props.history.push(`/admin/airlines/${airlineId}/fare_brands/${data.createFareBrand.id}`);
        })
        .catch(() => props.onError());
    } else {
      editFareBrand({
        variables: {
          id,
          cabinClassName,
          airlineId,
          name,
          bookingClass: bookingClass.map(bookingClassObj => bookingClassObj.value),
          refundable,
          cancelPenalty: parseFloat(cancelPenalty),
          cancelPenaltyCurrency,
          changeable,
          changePenalty: parseFloat(changePenalty),
          changePenaltyCurrency,
          noShow,
          noShowPenalty: parseFloat(noShowPenalty),
          noShowPenaltyCurrency,
          handLuggage,
          handLuggageCount: parseInt(handLuggageCount),
          handLuggageWeight: parseFloat(handLuggageWeight),
          fareBrandNameChange,
          seatReservation,
          co2Compensation,
          loungeAccess,
          priorityBoarding,
          priorityLuggage,
          exclusiveCheckIn,
          fastTrackSecurity,
          mileUpgrade,
          catering,
          welcomeDrink,
          digitalMagazines,
          flatbed,
          milesAward,
          milesStatus,
          milesAwardAmount: parseFloat(milesAwardAmount),
          milesStatusAmount: parseFloat(milesStatusAmount),
          supplierFareBrandsAttributes: normalizeMultiFormAttributes(supplierBrandNames),
        },
        refetchQueries: [getFareBrand(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/airlines/${airlineId}/fare_brands/${data.editFareBrand.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  const addNewSupplierBrandForm = () => {
    const newFromAttributes = { id: uuidv4(), supplierBrandName: '', supplierName: '' };

    setSupplierBrandNames(prevState => {
      updateHasSupplierProvidedNames([...prevState, newFromAttributes]);

      return [...prevState, newFromAttributes];
    });
  };

  const deleteSupplierBrandName = id => {
    setSupplierBrandNames(prevState => {
      let index = prevState.findIndex(item => item.id === id);

      if (uuidValidate(id)) {
        prevState.splice(index, 1);
        updateHasSupplierProvidedNames([...prevState]);
        return [...prevState];
      } else {
        let toBeDeletedItem = prevState.splice(index, 1);

        updateHasSupplierProvidedNames([
          ...prevState,
          { id: toBeDeletedItem[0].id, _destroy: true },
        ]);

        return [...prevState, { id: toBeDeletedItem[0].id, _destroy: true }];
      }
    });
  };

  const renderNewForm = () => {
    if (
      supplierBrandNames.length === 0 ||
      supplierBrandNames.every(item => item.hasOwnProperty('_destroy'))
    ) {
      setSupplierBrandNames([{ id: uuidv4(), supplierBrandName: '', supplierName: '' }]);
      setHasSupplierProvidedNames(true);
    }
  };

  useState(() => {
    if (
      supplierBrandNames.length === 0 ||
      supplierBrandNames.every(item => item.hasOwnProperty('_destroy'))
    ) {
      setHasSupplierProvidedNames(false);
    } else {
      setHasSupplierProvidedNames(true);
    }
  }, [supplierBrandNames]);

  const updateHasSupplierProvidedNames = supplierFareBrands => {
    if (
      supplierFareBrands.length === 0 ||
      supplierFareBrands.every(item => item.hasOwnProperty('_destroy'))
    ) {
      setHasSupplierProvidedNames(false);
    } else {
      setHasSupplierProvidedNames(true);
    }
  };

  return (
    <div className="fare-brand-form">
      <div className="fare-brand-form__title">
        {isNewRecord ? t('fareBrands.show.addNew') : t('fareBrands.show.edit')}
      </div>
      <div className="fare-band-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="fare-brand-form__container">
        <div className="col-grid col-bleed">
          <div className="col-12 col-bleed fare-brand-form__container-segment">
            <div className="fare-brand-form__container-segment-title">
              {t('fareBrands.show.airlineInformation')}
            </div>
            <div className="grid">
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('airlines.show.title')}
                  value={airlineName && `${airlineName} (${airlineCode})`}
                  disabled={true}
                  isClearable={false}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <SelectBox
                  value={cabinClassOptions.find(option => option.value === cabinClassName)}
                  onChange={selectedOption => setCabinClassName(selectedOption.value)}
                  options={cabinClassOptions}
                  required={true}
                  width="full"
                  hint={t('fareBrands.hints.cabinClass')}
                  label={t('fareBrands.attributes.cabinClass')}
                  disabled={disabledForUnverifiedFareBrand}
                  isClearable={false}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field fare-brand-form__container-segment-field-multi-select-box">
                <MultiSelectBox
                  value={bookingClass}
                  isMulti={true}
                  label={t('fareBrands.attributes.bookingClass')}
                  options={bookingClassOptions}
                  onChange={selectedOptions => setBookingClass(selectedOptions)}
                  isDisabled={disabledForUnverifiedFareBrand}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-bleed fare-brand-form__container-segment">
            <div className="fare-brand-form__container-segment-title">
              {t('fareBrands.show.basicInformation')}
            </div>
            <div className="grid">
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.name')}
                  value={name}
                  onChange={setName}
                  isClearable={false}
                />
              </div>
            </div>

            <div className="grid">
              <div className="col-3 fare-brand-form__container-segment-field">
                <div className=" col-bleed-x fare-brand-form__container-segment-field">
                  <div className="fare-brand-form__container-segment-field-radio-title">
                    {t('supplierFareBrand.edit.supplierFareBrand')}
                  </div>
                  <Radio
                    label={t('shared.yes')}
                    onChange={() => renderNewForm()}
                    name="supplierBrandNames"
                    checked={hasSupplierProvidedNames}
                  />
                  <Radio
                    label={t('shared.no')}
                    onChange={() => {
                      setSupplierBrandNames([]);
                      setHasSupplierProvidedNames(false);
                    }}
                    name="supplierBrandNames"
                    checked={!hasSupplierProvidedNames}
                  />
                </div>
              </div>

              <div className="col-9 col-bleed">
                {supplierBrandNames &&
                  supplierBrandNames.map((supplierBrandName, index, supplierBrandNames) => {
                    let isLastElement = false;

                    if (index + 1 === supplierBrandNames.length) {
                      isLastElement = true;
                    }

                    return (
                      <SupplierBrandName
                        item={supplierBrandName}
                        isEdit={true}
                        isLastElement={isLastElement}
                        setSupplierBrandNames={setSupplierBrandNames}
                        supplierList={supplierBrandNames}
                        addNewSupplierBrandForm={addNewSupplierBrandForm}
                        deleteSupplierBrandName={deleteSupplierBrandName}
                        updateHasSupplierProvidedNames={updateHasSupplierProvidedNames}
                        disabledForUnverifiedFareBrand={disabledForUnverifiedFareBrand}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="grid">
              <div className="col-3 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.refundable')}
                </div>
                <Radio
                  label={t('shared.yes')}
                  onChange={() => setRefundable('refundable')}
                  name="refundable"
                  checked={refundable === 'refundable'}
                />
                <Radio
                  label={t('shared.no')}
                  onChange={() => setRefundable('not_refundable')}
                  name="refundable"
                  checked={refundable === 'not_refundable'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setRefundable('no_refundable_information')}
                  name="refundable"
                  checked={refundable === 'no_refundable_information'}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.cancellationPenalty')}
                  value={cancelPenalty}
                  disabled={refundable !== 'refundable'}
                  onChange={setCancelPenalty}
                  isClearable={false}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.cancellationPenaltyCurrency')}
                  value={cancelPenaltyCurrency}
                  disabled={refundable !== 'refundable'}
                  onChange={setCancelPenaltyCurrency}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col-3 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.changeable')}
                </div>
                <Radio
                  label={t('shared.yes')}
                  onChange={() => setChangeable('changeable')}
                  name="changeable"
                  checked={changeable === 'changeable'}
                />
                <Radio
                  label={t('shared.no')}
                  onChange={() => setChangeable('not_changeable')}
                  name="changeable"
                  checked={changeable === 'not_changeable'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setChangeable('no_changeable_information')}
                  name="changeable"
                  checked={changeable === 'no_changeable_information'}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.changePenalty')}
                  value={changePenalty}
                  type="number"
                  disabled={changeable !== 'changeable'}
                  onChange={setChangePenalty}
                  isClearable={false}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.changePenaltyCurrency')}
                  value={changePenaltyCurrency}
                  disabled={changeable !== 'changeable'}
                  onChange={setChangePenaltyCurrency}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.noShow')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.supported')}
                  onChange={() => setNoShow('no_show_supported')}
                  name="no_show"
                  checked={noShow === 'no_show_supported'}
                />
                <Radio
                  label={t('fareBrands.attributes.notSupported')}
                  onChange={() => setNoShow('no_show_not_supported')}
                  name="no_show"
                  checked={noShow === 'no_show_not_supported'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setNoShow('no_no_show_information')}
                  name="no_show"
                  checked={noShow === 'no_no_show_information'}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.noShowPenalty')}
                  value={noShowPenalty}
                  type="number"
                  disabled={noShow !== 'no_show_supported'}
                  onChange={setNoShowPenalty}
                  isClearable={false}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.noShowPenaltyCurrency')}
                  value={noShowPenaltyCurrency}
                  disabled={noShow !== 'no_show_supported'}
                  onChange={setNoShowPenaltyCurrency}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.handLuggage')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.supported')}
                  onChange={() => setHandLuggage('hand_luggage_supported')}
                  name="hand_luggage"
                  checked={handLuggage === 'hand_luggage_supported'}
                />
                <Radio
                  label={t('fareBrands.attributes.notSupported')}
                  onChange={() => setHandLuggage('hand_luggage_not_supported')}
                  name="hand_luggage"
                  checked={handLuggage === 'hand_luggage_not_supported'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setHandLuggage('no_hand_luggage_information')}
                  name="hand_luggage"
                  checked={handLuggage === 'no_hand_luggage_information'}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.handLuggageCount')}
                  value={handLuggageCount}
                  type="number"
                  disabled={handLuggage !== 'hand_luggage_supported'}
                  onChange={setHandLuggageCount}
                  isClearable={false}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.handLuggageWeight')}
                  value={handLuggageWeight}
                  type="number"
                  disabled={handLuggage !== 'hand_luggage_supported'}
                  onChange={setHandLuggageWeight}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.fareBrandNameChange')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.supported')}
                  onChange={() => setFareBrandNameChange('fare_brand_name_change_supported')}
                  name="fare_brand_name_change"
                  checked={fareBrandNameChange === 'fare_brand_name_change_supported'}
                />
                <Radio
                  label={t('fareBrands.attributes.notSupported')}
                  onChange={() => setFareBrandNameChange('fare_brand_name_change_not_supported')}
                  name="fare_brand_name_change"
                  checked={fareBrandNameChange === 'fare_brand_name_change_not_supported'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setFareBrandNameChange('no_fare_brand_name_change_information')}
                  name="fare_brand_name_change"
                  checked={fareBrandNameChange === 'no_fare_brand_name_change_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.seatReservation')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.included')}
                  onChange={() => setSeatReservation('seat_reservation_included')}
                  name="seat_reservation"
                  checked={seatReservation === 'seat_reservation_included'}
                />
                <Radio
                  label={t('fareBrands.attributes.notIncluded')}
                  onChange={() => setSeatReservation('seat_reservation_not_included')}
                  name="seat_reservation"
                  checked={seatReservation === 'seat_reservation_not_included'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setSeatReservation('no_seat_reservation_information')}
                  name="seat_reservation"
                  checked={seatReservation === 'no_seat_reservation_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.co2Compensation')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setCo2Compensation('co2_compensation_available')}
                  name="co2_compensation"
                  checked={co2Compensation === 'co2_compensation_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setCo2Compensation('co2_compensation_not_available')}
                  name="co2_compensation"
                  checked={co2Compensation === 'co2_compensation_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setCo2Compensation('no_co2_compensation_information')}
                  name="co2_compensation"
                  checked={co2Compensation === 'no_co2_compensation_information'}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-bleed fare-brand-form__container-segment">
            <div className="fare-brand-form__container-segment-title">
              {t('fareBrands.show.airportFacilities')}
            </div>
            <div className="grid">
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.loungeAccess')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setLoungeAccess('lounge_access_available')}
                  name="lounge_access"
                  checked={loungeAccess === 'lounge_access_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setLoungeAccess('lounge_access_not_available')}
                  name="lounge_access"
                  checked={loungeAccess === 'lounge_access_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setLoungeAccess('no_lounge_access_information')}
                  name="lounge_access"
                  checked={loungeAccess === 'no_lounge_access_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.priorityBoarding')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setPriorityBoarding('priority_boarding_available')}
                  name="priority_boarding"
                  checked={priorityBoarding === 'priority_boarding_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setPriorityBoarding('priority_boarding_not_available')}
                  name="priority_boarding"
                  checked={priorityBoarding === 'priority_boarding_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setPriorityBoarding('no_priority_boarding_information')}
                  name="priority_boarding"
                  checked={priorityBoarding === 'no_priority_boarding_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.priorityLuggage')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setPriorityLuggage('priority_luggage_available')}
                  name="priority_luggage"
                  checked={priorityLuggage === 'priority_luggage_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setPriorityLuggage('priority_luggage_not_available')}
                  name="priority_luggage"
                  checked={priorityLuggage === 'priority_luggage_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setPriorityLuggage('no_priority_luggage_information')}
                  name="priority_luggage"
                  checked={priorityLuggage === 'no_priority_luggage_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.exclusiveCheckIn')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.supported')}
                  onChange={() => setExclusiveCheckIn('exclusive_check_in_supported')}
                  name="exclusive_check_in"
                  checked={exclusiveCheckIn === 'exclusive_check_in_supported'}
                />
                <Radio
                  label={t('fareBrands.attributes.notSupported')}
                  onChange={() => setExclusiveCheckIn('exclusive_check_in_not_supported')}
                  name="exclusive_check_in"
                  checked={exclusiveCheckIn === 'exclusive_check_in_not_supported'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setExclusiveCheckIn('no_exclusive_check_in_information')}
                  name="exclusive_check_in"
                  checked={exclusiveCheckIn === 'no_exclusive_check_in_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.fastTrackSecurity')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setFastTrackSecurity('fast_track_security_available')}
                  name="fast_track_security"
                  checked={fastTrackSecurity === 'fast_track_security_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setFastTrackSecurity('fast_track_security_not_available')}
                  name="fast_track_security"
                  checked={fastTrackSecurity === 'fast_track_security_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setFastTrackSecurity('no_fast_track_security_information')}
                  name="fast_track_security"
                  checked={fastTrackSecurity === 'no_fast_track_security_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.mileUpgrade')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.supported')}
                  onChange={() => setMileUpgrade('mile_upgrade_supported')}
                  name="mile_upgrade"
                  checked={mileUpgrade === 'mile_upgrade_supported'}
                />
                <Radio
                  label={t('fareBrands.attributes.notSupported')}
                  onChange={() => setMileUpgrade('mile_upgrade_not_supported')}
                  name="mile_upgrade"
                  checked={mileUpgrade === 'mile_upgrade_not_supported'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setMileUpgrade('no_mile_upgrade_information')}
                  name="mile_upgrade"
                  checked={mileUpgrade === 'no_mile_upgrade_information'}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-bleed fare-brand-form__container-segment">
            <div className="fare-brand-form__container-segment-title">
              {t('fareBrands.show.inFlightFacilities')}
            </div>
            <div className="grid">
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.catering')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.included')}
                  onChange={() => setCatering('catering_included')}
                  name="catering"
                  checked={catering === 'catering_included'}
                />
                <Radio
                  label={t('fareBrands.attributes.notIncluded')}
                  onChange={() => setCatering('catering_not_included')}
                  name="catering"
                  checked={catering === 'catering_not_included'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setCatering('no_catering_information')}
                  name="catering"
                  checked={catering === 'no_catering_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.welcomeDrink')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setWelcomeDrink('welcome_drink_available')}
                  name="welcome_drink"
                  checked={welcomeDrink === 'welcome_drink_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setWelcomeDrink('welcome_drink_not_available')}
                  name="welcome_drink"
                  checked={welcomeDrink === 'welcome_drink_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setWelcomeDrink('no_welcome_drink_information')}
                  name="welcome_drink"
                  checked={welcomeDrink === 'no_welcome_drink_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.digitalMagazines')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.included')}
                  onChange={() => setDigitalMagazines('digital_magazines_available')}
                  name="digital_magazines"
                  checked={digitalMagazines === 'digital_magazines_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notIncluded')}
                  onChange={() => setDigitalMagazines('digital_magazines_not_available')}
                  name="digital_magazines"
                  checked={digitalMagazines === 'digital_magazines_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setDigitalMagazines('no_digital_magazines_information')}
                  name="digital_magazines"
                  checked={digitalMagazines === 'no_digital_magazines_information'}
                />
              </div>
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.flatbed')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setFlatbed('flatbed_available')}
                  name="flatbed"
                  checked={flatbed === 'flatbed_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setFlatbed('flatbed_not_available')}
                  name="flatbed"
                  checked={flatbed === 'flatbed_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setFlatbed('no_flatbed_information')}
                  name="flatbed"
                  checked={flatbed === 'no_flatbed_information'}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-bleed fare-brand-form__container-segment">
            <div className="fare-brand-form__container-segment-title">
              {t('fareBrands.show.loyaltyProgram')}
            </div>
            <div className="grid">
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.milesAward')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setMilesAward('miles_award_available')}
                  name="miles_award"
                  checked={milesAward === 'miles_award_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setMilesAward('miles_award_not_available')}
                  name="miles_award"
                  checked={milesAward === 'miles_award_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setMilesAward('no_miles_award_information')}
                  name="miles_award"
                  checked={milesAward === 'no_miles_award_information'}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.milesAwardAmount')}
                  value={milesAwardAmount}
                  disabled={milesAward !== 'miles_award_available'}
                  type="number"
                  onChange={setMilesAwardAmount}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="grid">
              <div className="col-4 fare-brand-form__container-segment-field">
                <div className="fare-brand-form__container-segment-field-radio-title">
                  {t('fareBrands.attributes.milesStatus')}
                </div>
                <Radio
                  label={t('fareBrands.attributes.available')}
                  onChange={() => setMilesStatus('miles_status_available')}
                  name="miles_status"
                  checked={milesStatus === 'miles_status_available'}
                />
                <Radio
                  label={t('fareBrands.attributes.notAvailable')}
                  onChange={() => setMilesStatus('miles_status_not_available')}
                  name="miles_status"
                  checked={milesStatus === 'miles_status_not_available'}
                />
                <Radio
                  label={t('shared.noInformation')}
                  onChange={() => setMilesStatus('no_miles_status_information')}
                  name="miles_status"
                  checked={milesStatus === 'no_miles_status_information'}
                />
              </div>
              <div className="col-3 fare-brand-form__container-segment-field">
                <Input
                  label={t('fareBrands.attributes.milesStatusAmount')}
                  type="number"
                  value={milesStatusAmount}
                  disabled={milesStatus !== 'miles_status_available'}
                  onChange={setMilesStatusAmount}
                  isClearable={false}
                />
              </div>
            </div>
          </div>
          <div className="fare-brand-form__submit-button">
            <Button
              label={isNewRecord ? t('shared.create') : t('shared.update')}
              onClick={submitToBackEnd}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(FareBrandForm);
