import React, { useState, useEffect } from 'react';
import Button from '@wtag/rcl-button';
import { useTranslation } from 'react-i18next';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import supplierKeys from '../../helpers/supplierKeys';

const SupplierBrandName = ({
  item,
  isEdit,
  isLastElement,
  setSupplierBrandNames,
  addNewSupplierBrandForm,
  deleteSupplierBrandName,
  updateHasSupplierProvidedNames,
  disabledForUnverifiedFareBrand,
}) => {
  const { t } = useTranslation();

  const supplierBrandNameOptions = supplierKeys.map(key => ({
    label: t(`supplierFareBrand.suppliers.${key}`),
    value: key,
  }));

  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [supplierBrandName, setSupplierBrandName] = useState(null);

  const updateSupplierBrandNames = (id, name, brandName) => {
    setSupplierBrandNames(prevState => {
      let indexOfSupplierFareBrands = prevState.findIndex(item => item.id === id);
      prevState.splice(indexOfSupplierFareBrands, 1);

      updateHasSupplierProvidedNames([
        ...prevState,
        { id, supplierBrandName: name, supplierName: brandName },
      ]);

      return [...prevState, { id, supplierBrandName: name, supplierName: brandName }];
    });
  };

  useEffect(() => {
    if(item.id !== id){
      setId(item.id);
    }
    if(item.supplierBrandName !== name){
      setName(item.supplierBrandName);
    }
    if(item.supplierName !== id){
      setSupplierBrandName(item.supplierName);
    }
  }, [item.id, item.supplierName, item.supplierBrandName]); // eslint-disable-line react-hooks/exhaustive-deps

  if (item && item._destroy) {
    return null;
  }

  return (
    <React.Fragment>
      {!isEdit ? (
        <div className="fare-brand-show__supplier-brand-name">
          <div className="col-6 col-bleed-x fare-brand-show__segment-infos--no-margin">
            <div className="fare-brand-show__segment-infos--field">
              {t('supplierFareBrand.attributes.supplierProvidedName')}
            </div>
            <div className="fare-brand-show__segment-infos--value">
              {item.supplierBrandName || t('shared.noInformation')}
            </div>
          </div>

          <div className="col-6 fare-brand-show__segment-infos">
            <div className="fare-brand-show__segment-infos--field">
              {t('supplierFareBrand.attributes.supplier')}
            </div>
            <div className="fare-brand-show__segment-infos--value">
              {t(`supplierFareBrand.suppliers.${item.supplierName}`) || t('shared.noInformation')}
            </div>
          </div>
        </div>
      ) : (
        <div className="fare-brand-show__supplier-brand-name">
          <div className="col-4 fare-brand-form__container-segment-field">
            <Input
              label={t('supplierFareBrand.attributes.supplierProvidedName')}
              value={name}
              onChange={value => {
                setName(value);
                updateSupplierBrandNames(id, value, supplierBrandName);
              }}
              disabled={disabledForUnverifiedFareBrand}
              isClearable={false}
            />
          </div>
          <div className="col-4 fare-brand-form__container-segment-field">
            <SelectBox
              value={supplierBrandNameOptions.find(option => option.value === supplierBrandName)}
              onChange={selectedOption => {
                setSupplierBrandName(selectedOption.value);
                updateSupplierBrandNames(id, name, selectedOption.value);
              }}
              options={supplierBrandNameOptions}
              required={true}
              width="full"
              label={t('supplierFareBrand.attributes.supplier')}
              disabled={disabledForUnverifiedFareBrand}
              isClearable={false}
            />
          </div>

          <div className="fare-brand-form__submit-button fare-brand-show__submit-button-button">
            <Button
              label={t('supplierFareBrand.edit.delete')}
              onClick={() => deleteSupplierBrandName(id)}
              disabled={disabledForUnverifiedFareBrand}
            />
            {isLastElement && (
              <Button
                label={t('supplierFareBrand.edit.addNew')}
                onClick={() => addNewSupplierBrandForm()}
                disabled={disabledForUnverifiedFareBrand}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

SupplierBrandName.defaultProps = {
  disabledForUnverifiedFareBrand: false,
}

export default SupplierBrandName;
