import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from '@wtag/rcl-icon';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import classNames from 'classnames';

import { itemsPerPage, maxPageNumberForFullLoad } from '../../variables';
import Header from './Header';
import Paginate from './Paginate';
import IconButton from '../rclComponents/IconButton';
import MetaIcon from '../icon/Icon';
import EmptyContentPlaceholder from './EmptyContentPlaceholder';
import { Tabs, Tab, TabList, TabPanel } from '../rclComponents/Tabs';
import Radio from '../rclComponents/Radio';

import toSnakeCase from '../../helpers/toSnakeCase';
import isEntityAvailable from '../../helpers/isEntityAvailable';
import GET_COUNT_OF_TOTAL_CURSORS from '../../graphql/queries/totalCursorsCount';
import SpinnerIcon from '../../assets/icons/spinner.svg';

const List = props => {
  const { t } = useTranslation();

  const {
    showSearchAttributes,
    searchAttributes,
    deleteItem,
    getCursorsForItems,
    itemType,
    getItems,
    minLengthToSearch,
    conditionalAttributes,
    customRenderForConditionalAttributes,
    isColumnSortable,
    listAttributes,
    disableDeletion,
    title,
    modelName,
    linkTo,
    buttonLabel,
    children,
    showTabs,
    tabTitles,
  } = props;

  const [cursorState, setCursorState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(3);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [searchParam, setSearchParam] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [cursors, setCursors] = useState([]);
  const [cursorFetchNumber, setCursorFetchNumber] = useState(itemsPerPage * 4);
  const [requiredLengthToSearch, setRequiredLengthToSearch] = useState(2);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchAttributeIndex, setSearchAttributeIndex] = useState(0);
  const [searchAttribute, setSearchAttribute] = useState(
    showSearchAttributes && searchAttributes[0],
  );
  const [sortAttribute, setSortAttribute] = useState('');
  const [sortAttributeDirection, setSortAttributeDirection] = useState('');

  const [deleteOneItem] = useMutation(deleteItem);
  const cursorData = useQuery(getCursorsForItems, {
    variables: {
      first: cursorFetchNumber,
      after: currentCursor,
      query: searchParam,
      currentTab: tabIndex,
      currentSearchAttribute: searchAttribute,
      currentSortAttribute: sortAttribute,
      currentSortAttributeDirection: sortAttributeDirection,
    },
    fetchPolicy: 'network-only',
  });

  const cursorCountData = useQuery(GET_COUNT_OF_TOTAL_CURSORS, {
    variables: {
      modelName: itemType,
      query: searchParam,
      currentTab: tabIndex,
      currentSearchAttribute: searchAttribute,
    },
  });

  const { client, loading, data } = useQuery(getItems, {
    variables: {
      first: itemsPerPage,
      after: currentCursor,
      query: searchParam,
      currentTab: tabIndex,
      currentSearchAttribute: searchAttribute,
      currentSortAttribute: sortAttribute,
      currentSortAttributeDirection: sortAttributeDirection,
    },
    fetchPolicy: 'network-only',
  });

  const callSearchParam = useRef(
    debounce(async inputParam => {
      setSearchParam(inputParam);
    }, 1500),
  );

  const resetSearch = () => {
    setSearchParam('');
    setSearchValue('');
  };

  const postIcon = () => {
    if (loading) {
      return <img className="list__search-post-icon" src={SpinnerIcon} alt="spinner" />;
    }
    return (
      <IconButton
        size="large"
        className="list__search-post-icon"
        isIconOnly={true}
        icon={<MetaIcon name="close" />}
        onClick={resetSearch}
      />
    );
  };

  const forcePage = currentPage - 1;

  const changePage = useCallback(
    value => {
      setCurrentPage(value.selected + 1);
      const cursorIndexFromCurrentPage = value.selected * itemsPerPage - 1;
      if (cursorIndexFromCurrentPage < 0) {
        setCurrentCursor(null);
      } else {
        setCurrentCursor(cursors[cursorIndexFromCurrentPage]);
      }
    },
    [cursors],
  );

  useEffect(() => {
    if (cursorCountData && cursorCountData.data && !cursorCountData.loading) {
      const count = cursorCountData.data.totalCursorsCount / itemsPerPage;
      const pageCount = Math.ceil(count);
      if (minLengthToSearch > 0) {
        setRequiredLengthToSearch(minLengthToSearch);
        setCursorFetchNumber(itemsPerPage * 4);
      } else if (pageCount <= maxPageNumberForFullLoad) {
        setRequiredLengthToSearch(3);
        setCursorFetchNumber(null);
      } else {
        setRequiredLengthToSearch(2);
        setCursorFetchNumber(itemsPerPage * 4);
      }
      if (pageCount < currentPage) {
        changePage({ selected: 0 });
      }
      setPageCount(pageCount);
    }
    if (cursorData && cursorData.data && !cursorData.loading && !cursorState) {
      setCursorState(true);
      const oldCursors = Object.assign([], cursors);
      const newCursors = cursorData.data[itemType].edges.map(edge => edge.cursor);
      const finalCursors = oldCursors.concat(
        newCursors.filter(newCursor => oldCursors.indexOf(newCursor) < 0),
      );
      setCursors(finalCursors);
    }
  }, [
    cursorCountData,
    cursorData,
    itemType,
    currentPage,
    changePage,
    cursors,
    minLengthToSearch,
    cursorState,
  ]);

  const onDelete = id => {
    window.confirm(t(`${itemType}.index.confirmDelete`)) &&
      deleteOneItem({
        variables: { id },
      }).then(() => client.resetStore());
  };

  const onSearchParamChange = value => {
    setSearchValue(value);
    if (value === '' || value.length >= requiredLengthToSearch) {
      callSearchParam.current(value);
    }
  };

  const toggleSearchAttributeIndex = id => {
    setSearchAttributeIndex(id);
    setSearchAttribute(searchAttributes[id]);
  };

  const setSortingColumn = item => {
    setSortAttribute(item);
    if (sortAttributeDirection === 'down') {
      setSortAttributeDirection('up');
    } else {
      setSortAttributeDirection('down');
    }
  };

  const isColumnSelected = (item, direction) =>
    sortAttribute === item && sortAttributeDirection && sortAttributeDirection === direction;

  const renderAttributeConditionally = (attributeName, attributeValue) => {
    if (conditionalAttributes.includes(attributeName)) {
      return customRenderForConditionalAttributes(attributeValue);
    } else if (typeof attributeValue === 'boolean') {
      return attributeValue.toString();
    }

    return attributeValue;
  };

  const headerWithActions = item => {
    if (isColumnSortable) {
      return (
        <div
          className="list__value list__value-clickable list__value-title"
          key={item}
          role="presentation"
          onClick={() => setSortingColumn(item)}
        >
          <p>{t(`${itemType}.attributes.${item}`)}</p>

          <div
            className={classNames('list__value-clickable-icon', {
              'list__value-clickable-icon--active': isColumnSelected(item, 'up'),
            })}
          >
            <Icon name="arrowUp" />
          </div>
          <div
            className={classNames('list__value-clickable-icon', {
              'list__value-clickable-icon--active': isColumnSelected(item, 'down'),
            })}
          >
            <Icon name="arrowDown" />
          </div>
        </div>
      );
    }

    return (
      <div className="list__value list__value-title" key={item}>
        <p>{t(`${itemType}.attributes.${item}`)}</p>
      </div>
    );
  };

  const contents = (
    <div>
      {loading && <div className="lds-dual-ring" />}
      {!loading && (
        <React.Fragment>
          {isEntityAvailable(data, itemType) ? (
            <>
              <div className="list__pagination">
                <Paginate
                  pageCount={pageCount}
                  onPageChange={changePage}
                  forcePage={forcePage}
                  marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                />
              </div>
              <div className="list__header">
                {listAttributes.map(item => headerWithActions(item))}
                <div
                  className={classNames('list__value list__value--actions', {
                    'list__value--actions-small': disableDeletion,
                  })}
                >
                  <p>{t('list.header.actions')}</p>
                </div>
              </div>
              <div className="list__table">
                {data[itemType].edges.map(data => (
                  <div className="list__data" key={data.node.id}>
                    {listAttributes.map(item => (
                      <div className="list__value" key={item}>
                        <div className="list__value-text" key={item}>
                          {renderAttributeConditionally(item, data.node[item])}
                        </div>
                      </div>
                    ))}
                    <div
                      className={classNames('list__value list__value--actions', {
                        'list__value--actions-small': disableDeletion,
                      })}
                    >
                      <Link to={`/admin/${toSnakeCase(itemType)}/${data.node.id}`}>
                        <Button label={t('shared.show')} />
                      </Link>
                      <Link to={`/admin/${toSnakeCase(itemType)}/${data.node.id}/edit`}>
                        <Button label={t('shared.edit')} />
                      </Link>
                      {!disableDeletion && (
                        <Button label={t('shared.delete')} onClick={() => onDelete(data.node.id)} />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="list__pagination">
                <Paginate
                  pageCount={pageCount}
                  onPageChange={changePage}
                  forcePage={forcePage}
                  marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                />
              </div>
            </>
          ) : (
            <EmptyContentPlaceholder entityName={t(title).toLowerCase()} modelName={modelName} />
          )}
        </React.Fragment>
      )}
    </div>
  );

  return (
    <div className="list">
      <Header title={title} linkTo={linkTo} buttonLabel={buttonLabel}>
        {children}
      </Header>
      <div>
        <Input
          label={t('list.search.label')}
          value={searchValue}
          onChange={onSearchParamChange}
          placeholder={t(`${itemType}.index.searchPlaceholder`)}
          postIcon={postIcon()}
        />
      </div>
      {showSearchAttributes && <h2>{t('list.search.options')}</h2>}
      {showSearchAttributes &&
        searchAttributes.map(function(value, i) {
          return (
            <div key={value}>
              <Radio
                label={t(`${itemType}.index.${value}`)}
                onChange={() => toggleSearchAttributeIndex(i)}
                name="searchAttributes"
                checked={searchAttributeIndex === i}
              />
            </div>
          );
        })}
      {showTabs ? (
        <Tabs className="list__tabs" onSelect={index => setTabIndex(index)}>
          <TabList>
            {tabTitles.map(title => (
              <Tab key={title}>{title}</Tab>
            ))}
          </TabList>
          {tabTitles.map(title => (
            <TabPanel key={title}>{contents}</TabPanel>
          ))}
        </Tabs>
      ) : (
        contents
      )}
    </div>
  );
};

List.defaultProps = {
  deleteItem: gql`
    mutation null {
      null
    }
  `,
  disableDeletion: false,
  linkTo: '',
  buttonLabel: '',
  showSearchAttributes: false,
  minLengthToSearch: 0,
  isColumnSortable: false,
  conditionalAttributes: [],
  customRenderForConditionalAttributes: () => {},
};

List.prototype = {
  getItems: PropTypes.object.isRequired,
  getCursorsForItems: PropTypes.object.isRequired,
  listAttributes: PropTypes.object.isRequired,
  conditionalAttributes: PropTypes.arrayOf(PropTypes.string),
  customRenderForConditionalAttributes: PropTypes.func,
  deleteItem: PropTypes.object,
  disableDeletion: PropTypes.bool,
  itemType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  linkTo: PropTypes.string,
  isColumnSortable: PropTypes.bool,
  showSearchAttributes: PropTypes.bool,
  minLengthToSearch: PropTypes.number,
};

export default List;
