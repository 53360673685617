import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import { Textarea } from '@wtag/react-comp-lib';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_AIRLINE } from '../../graphql/queries/airline';
import { GET_COUNTRIES } from '../../graphql/queries/country';

import MultiSelectBox from '../rclComponents/selectBox';
import { CREATE_AIRLINE, EDIT_AIRLINE } from '../../graphql/mutations/airline';
import imageUrl from '../../helpers/imageUrl';
import clearCache from '../../helpers/clearCache';
import FileUpload from '../common/FileUpload';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import TextEditor from '../common/TextEditor';
import { getAirline } from '../../graphql/queries/airline';
import returnEmptyStringWhenNull from '../../helpers/returnEmptyStringWhenNull';
import UnsplashImageSearch from '../unsplashImageSearch';
import NoResultsFound from '../common/NoResultsFound';
import { debounce } from 'lodash';

const AirlineForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createAirline, callbackDataOnCreate] = useMutation(CREATE_AIRLINE, {
    update: cache => clearCache(cache, /Airline/),
  });
  const [editAirline, callbackDataOnEdit] = useMutation(EDIT_AIRLINE);
  const { loading: countriesLoading, data: countriesData } = useQuery(GET_COUNTRIES);
  const airlineFromId = useQuery(GET_AIRLINE, {
    variables: { id },
    skip: isNewRecord,
  });
  const cabinClassOptions = ['economy', 'premium_economy', 'business', 'first_class'].map(
    cabinClass => ({
      label: t(`fareBrands.attributes.${cabinClass}`),
      value: cabinClass,
    }),
  );

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [icaoCode, setIcaoCode] = useState('');
  const [url, setUrl] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [cabinClassNames, setCabinClassNames] = useState([]);

  const [instagram, setInstagram] = useState('');
  const [description, setDescription] = useState('');
  const [publishDescription, setPublishDescription] = useState(false);
  const [checkInUrl, setCheckInUrl] = useState('');
  const [countryId, setCountryId] = useState('');
  const [legalName, setLegalName] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');

  const [deactivated, setDeactivated] = useState(false);
  const [image, setImage] = useState(null);
  const [previousImage, setPreviousImage] = useState(null);
  const [previousLogo, setPreviousLogo] = useState(null);
  const [logo, setLogo] = useState(null);

  const onNameChange = value => {
    setName(value);
  };

  const onCodeChange = value => {
    setCode(value);
  };
  const onIcaoCodeChange = value => {
    setIcaoCode(value);
  };
  const onUrlChange = value => {
    setUrl(value);
  };
  const onFacebookChange = value => {
    setFacebook(value);
  };
  const onTwitterChange = value => {
    setTwitter(value);
  };
  const onTwitterHandleChange = value => {
    setTwitterHandle(value);
  };
  const onInstagramChange = value => {
    setInstagram(value);
  };
  const onCheckInUrlChange = value => {
    setCheckInUrl(value);
  };

  const onCountryIdChange = selectedCountry => {
    setCountryId(selectedCountry.value);
    setSelectedCountry(selectedCountry);
  };

  const onDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const onPublishDescriptionChange = selectedOption => {
    setPublishDescription(selectedOption.value);
  };

  const onLegalNameChange = value => {
    setLegalName(value);
  };
  const onAddressLineChange = value => {
    setAddressLine(value);
  };
  const onPostalCodeChange = value => {
    setPostalCode(value);
  };
  const onCityChange = value => {
    setCity(value);
  };
  const onStateChange = value => {
    setState(value);
  };
  const onDeactivatedChange = selectedOption => {
    setDeactivated(selectedOption.value);
  };

  const onTermsAndConditionsChange = value => {
    setTermsAndConditions(value);
  };

  const onImageFileUpload = files => {
    setImage(files[0]);
  };

  const onLogoFileUpload = files => {
    setLogo(files[0]);
  };

  useEffect(() => {
    if (airlineFromId && airlineFromId.data && !airlineFromId.loading) {
      const airline = airlineFromId.data.airline;
      setName(airline.name);
      setCode(airline.code);
      setIcaoCode(airline.icaoCode);
      setUrl(returnEmptyStringWhenNull(airline.url));
      setFacebook(returnEmptyStringWhenNull(airline.facebook));
      setTwitterHandle(returnEmptyStringWhenNull(airline.twitterHandle));
      setTwitter(returnEmptyStringWhenNull(airline.twitter));
      setInstagram(returnEmptyStringWhenNull(airline.instagram));
      setDescription(returnEmptyStringWhenNull(airline.description));
      setPublishDescription(airline.publishDescription);
      setCheckInUrl(returnEmptyStringWhenNull(airline.checkInUrl));
      setLegalName(returnEmptyStringWhenNull(airline.legalName));
      setAddressLine(returnEmptyStringWhenNull(airline.addressLine));
      setPostalCode(returnEmptyStringWhenNull(airline.postalCode));
      setCity(returnEmptyStringWhenNull(airline.city));
      setState(returnEmptyStringWhenNull(airline.state));
      setCountryId(airline.country.id);
      setDeactivated(airline.deactivated);
      setTermsAndConditions(returnEmptyStringWhenNull(airline.termsAndConditions));
      setPreviousImage(airline.imageUrls.large);
      setPreviousLogo(airline.logoUrl);
      setCabinClassNames(
        airline.cabinClasses.map(cabinClass => ({
          label: t(`fareBrands.attributes.${cabinClass.name}`),
          value: cabinClass.name,
        })),
      );
    }
  }, [airlineFromId]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitToBackEnd = debounce(() => {
    props.resetError();

    const cabinClassNamesArray = cabinClassNames.map(nameObj => nameObj.value);

    if (isNewRecord) {
      createAirline({
        variables: {
          name,
          code,
          cabinClassNames: cabinClassNamesArray,
          icaoCode,
          url,
          facebook,
          twitter,
          instagram,
          description,
          publishDescription,
          checkInUrl,
          twitterHandle,
          legalName,
          addressLine,
          postalCode,
          city,
          state,
          countryId,
          deactivated,
          termsAndConditions,
          image,
          logo,
        },
      })
        .then(({ data }) => {
          props.history.push(`/admin/airlines/${data.createAirline.id}`);
        })
        .catch(() => props.onError());
    } else {
      editAirline({
        variables: {
          id,
          name,
          code,
          cabinClassNames: cabinClassNamesArray,
          icaoCode,
          url,
          facebook,
          twitter,
          instagram,
          description,
          publishDescription,
          checkInUrl,
          twitterHandle,
          legalName,
          addressLine,
          postalCode,
          city,
          state,
          countryId,
          deactivated,
          termsAndConditions,
          image,
          logo,
        },
        refetchQueries: [getAirline(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/airlines/${data.editAirline.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  const deactivatedOptions = [
    { value: true, label: t('shared.true') },
    { value: false, label: t('shared.false') },
  ];

  const publishDescriptionOptions = [
    { value: true, label: t('shared.true') },
    { value: false, label: t('shared.false') },
  ];

  let countryOptions;

  if (countriesLoading) {
    countryOptions = [];
  } else {
    countryOptions = countriesData.countries.edges.map(country => ({
      value: country.node.id,
      label: country.node.name,
    }));
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord ?
          t('shared.new', { entityName: t('airlines.show.title') })
          : t('shared.editTitle', { entityName: t('airlines.show.title')})}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-6 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field airline-form__name">
                <Input
                    label={t('airlines.attributes.name')}
                    value={name}
                    onChange={onNameChange}
                    isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__code">
                <Input
                    label={t('airlines.attributes.code')}
                    value={code}
                    onChange={onCodeChange}
                    isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__icao-code">
                <Input
                  label={t('airlines.attributes.icaoCode')}
                  value={icaoCode}
                  onChange={onIcaoCodeChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__instagram">
                <Input
                  label={t('airlines.attributes.instagram')}
                  value={instagram}
                  onChange={onInstagramChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__check-in-url">
                <Input
                  label={t('airlines.attributes.checkInUrl')}
                  value={checkInUrl}
                  onChange={onCheckInUrlChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field airline-form__url">
                <Input
                    label={t('airlines.attributes.url')}
                    value={url}
                    onChange={onUrlChange}
                    isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__facebook">
                <Input
                  label={t('airlines.attributes.facebook')}
                  value={facebook}
                  onChange={onFacebookChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__twitter">
                <Input
                  label={t('airlines.attributes.twitter')}
                  value={twitter}
                  onChange={onTwitterChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__twitter-handle">
                <Input
                  label={t('airlines.attributes.twitterHandle')}
                  value={twitterHandle}
                  onChange={onTwitterHandleChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field common-form__field-multi-select-box airline-form__cabin-class">
                <MultiSelectBox
                  value={cabinClassNames}
                  isMulti={true}
                  label={t('fareBrands.attributes.cabinClass')}
                  options={cabinClassOptions}
                  onChange={selectedOptions => setCabinClassNames(selectedOptions)}
                />
              </div>
            </div>
            <div className="col-12 col-bleed-y">
              <div className="common-form__field airline-form__legalName">
                <Input
                  label={t('airlines.attributes.legalName')}
                  value={legalName}
                  onChange={onLegalNameChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__address-line">
                <Input
                  label={t('airlines.attributes.addressLine')}
                  value={addressLine}
                  onChange={onAddressLineChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field airline-form__postal-code">
                <Input
                  label={t('airlines.attributes.postalCode')}
                  value={postalCode}
                  onChange={onPostalCodeChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__city">
                <Input
                  label={t('airlines.attributes.city')}
                  value={city}
                  onChange={onCityChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field airline-form__state">
                <Input
                  label={t('airlines.attributes.state')}
                  value={state}
                  onChange={onStateChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field common-form__field-select-box airline-form__country">
                <SelectBox
                  label={t('airlines.attributes.country')}
                  value={selectedCountry}
                  options={countryOptions}
                  onChange={onCountryIdChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                  width="small"
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-12 col-bleed-y">
              <div className="common-form__field airline-form__description">
                <Textarea
                  label={t('airlines.attributes.description')}
                  value={description}
                  onChange={onDescriptionChange}
                  placeholder=''
                />
              </div>
              <div className="common-form__field airline-form__publish-description">
                <SelectBox
                  label={t('airlines.attributes.publishDescription')}
                  value={{
                    value: publishDescription,
                    label: t(`shared.boolean`, {
                      context: publishDescription,
                      defaultValue: publishDescription ? 'True' : 'False'
                    })
                  }}
                  options={publishDescriptionOptions}
                  onChange={onPublishDescriptionChange}
                  width="full"
                  isClearable={false}
                />
              </div>
              <div className="common-form__field airline-form__deactivated">
                <SelectBox
                  label={t('airlines.attributes.deactivated')}
                  value={{
                    value: deactivated,
                    label: t(`shared.boolean`, {
                      context: deactivated,
                      defaultValue: deactivated ? 'True' : 'False'
                    })
                  }}
                  options={deactivatedOptions}
                  onChange={onDeactivatedChange}
                  width="full"
                  isClearable={false}
                />
              </div>
              <div className="common-form__submit-button">
                <Button
                  label={isNewRecord ? t('shared.create') : t('shared.update')}
                  onClick={submitToBackEnd}
                />
              </div>
            </div>
          </div>
          <div className="col-6 col-bleed-y">
            <div>
              <h2>{t('airlines.attributes.termsAndConditions')}</h2>
              <TextEditor onChange={onTermsAndConditionsChange} value={termsAndConditions} />
            </div>
            <h2>{t('airlines.attributes.logo')}</h2>
            {previousLogo && (
              <div className="common-form__previous-image">
                <img src={imageUrl(previousLogo)} alt="preview" />
              </div>
            )}
            <FileUpload onFileUpload={onLogoFileUpload} multiple={false} />
            <h2>{t('airlines.attributes.image')}</h2>
            {previousImage && (
              <div className="common-form__previous-image">
                <img src={imageUrl(previousImage)} alt="preview" />
              </div>
            )}
            <FileUpload onFileUpload={onImageFileUpload} multiple={false} />

            {!isNewRecord && (
              <UnsplashImageSearch
                setPreviousImage={setPreviousImage}
                imageableType={'Airline'}
                imageableId={id}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default WithErrorHandler(AirlineForm);
