import React, { useState } from 'react';
import Button from '@wtag/rcl-button';
import { Link } from 'react-router-dom';
import Icon from '@wtag/rcl-icon';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import translatedObjOfDefaultLocale from '../../helpers/translatedObjOfDefaultLocale';
import { DEFAULT_LOCALE } from '../../variables';

const CityTableRow = ({ data, listAttributes, onDelete }) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);
  const MIN_LENGTH_FOR_EXPAND = 1;

  const translatedCities = data.translatedNames;
  const translatedCityOfCurrentLocale = translatedObjOfDefaultLocale(translatedCities);
  const translatedCityOfOtherLocale = translatedCities.filter(
    city => city.locale !== DEFAULT_LOCALE,
  );

  const renderValue = (item, value) => {
    if (item === 'name' && translatedCities.length > MIN_LENGTH_FOR_EXPAND) {
      return (
        <div
          className="city-list__data-value city-list__data-value-clickable"
          key={item}
          role="presentation"
          onClick={() => setExpand(prevState => !prevState)}
        >
          <div>{value}</div>
          <div className="city-list__data-value-clickable-icon">
            {expand ? <Icon name="iconUpChevron" /> : <Icon name="iconDownChevron" />}
          </div>
        </div>
      );
    }

    return (
      <div className="city-list__data-value" key={item}>
        <div key={item}>{value}</div>
      </div>
    );
  };

  return (
    <React.Fragment key={data.id}>
      <div className="city-list__data">
        {listAttributes.map(item => renderValue(item, translatedCityOfCurrentLocale[item]))}
        <div className="city-list__data-value city-list__data-value--actions">
          <Link to={`/admin/cities/${data.id}`}>
            <Button label={t('shared.show')} />
          </Link>
          <Link to={`/admin/cities/${data.id}/edit`}>
            <Button label={t('shared.edit')} />
          </Link>
          <Button label={t('shared.delete')} onClick={() => onDelete(data.id)} />
        </div>
      </div>
      {expand && (
        <React.Fragment>
          {translatedCityOfOtherLocale.map(translatedCity => (
            <div className="city-list__data city-list__data--nested" key={translatedCity.id}>
              <div className="city-list__data-value">
                <div>{translatedCity.name}</div>
              </div>
              <div className="city-list__data-value">
                <div>{translatedCity.locale}</div>
              </div>
            </div>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

CityTableRow.propTypes = {
  data: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  listAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CityTableRow;
