import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import { Textarea } from '@wtag/react-comp-lib';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { GET_COUNTRY } from '../../graphql/queries/country';
import { GET_CONTINENTS } from '../../graphql/queries/continent';
import { CREATE_COUNTRY, EDIT_COUNTRY } from '../../graphql/mutations/country';
import normalizeMultiFormAttributes from '../../helpers/normalizeMultiFormAttributes';
import { useTranslation } from 'react-i18next';
import imageUrl from '../../helpers/imageUrl';
import clearCache from '../../helpers/clearCache';
import FileUpload from '../common/FileUpload';
import NameTranslationForm from '../common/NameTranslationForm';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import { getCountry } from '../../graphql/queries/country';
import UnsplashImageSearch from '../unsplashImageSearch';
import NoResultsFound from '../common/NoResultsFound';
import { DEFAULT_LOCALE } from '../../variables';

const CountryForm = props => {
  const { t } = useTranslation();
  let { id } = useParams();
  const isNewRecord = !id;

  const [createCountry, callbackDataOnCreate] = useMutation(CREATE_COUNTRY, {
    update: cache => clearCache(cache, /Country/),
  });
  const [translationsAttributes, setTranslationsAttributes] = useState([
    { id: uuidv4(), locale: DEFAULT_LOCALE, name: '', nationality: '' },
  ]);
  const [code, setCode] = useState('');
  const [continentId, setContinentId] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [apisDataRequired, setApisDataRequired] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [previousImage, setPreviousImage] = useState(null);
  const [publishDescription, setPublishDescription] = useState(false);
  const [editCountry, callbackDataOnEdit] = useMutation(EDIT_COUNTRY);
  const { loading, data } = useQuery(GET_CONTINENTS);
  const countryFromId = useQuery(GET_COUNTRY, {
    variables: { id },
    skip: isNewRecord,
  });

  const onCodeChange = value => {
    setCode(value);
  };

  const onPhoneCodeChange = value => {
    setPhoneCode(value);
  };

  const onContinentIdChange = selectedOption => {
    setContinentId(selectedOption.value);
  };

  const onApisDataRequiredChange = selectedOption => {
    setApisDataRequired(selectedOption.value);
  };

  const onDescriptionChange = event => {
    setDescription(event.target.value);
  };

  const onPublishDescriptionChange = selectedOption => {
    setPublishDescription(selectedOption.value);
  };

  useEffect(() => {
    if (countryFromId && countryFromId.data && !countryFromId.loading) {
      const country = countryFromId.data.country;
      setTranslationsAttributes(country.translatedNames);
      setCode(country.code);
      setPhoneCode(country.phoneCode);
      setContinentId(country.continent.id);
      setApisDataRequired(country.apisDataRequired);
      setDescription(country.description);
      setPreviousImage(country.imageUrls.large);
      setPublishDescription(country.publishDescription);
    }
  }, [countryFromId]);

  const submitToBackEnd = debounce(() => {
    props.resetError();

    if (isNewRecord) {
      createCountry({
        variables: {
          translationsAttributes: normalizeMultiFormAttributes(translationsAttributes),
          code,
          continentId,
          phoneCode: phoneCode,
          apisDataRequired,
          description,
          publishDescription,
          image,
        },
      })
        .then(({ data }) => {
          props.history.push(`/admin/countries/${data.createCountry.id}`);
        })
        .catch(() => props.onError());
    } else {
      editCountry({
        variables: {
          id,
          translationsAttributes: normalizeMultiFormAttributes(translationsAttributes),
          code,
          continentId,
          phoneCode: phoneCode,
          apisDataRequired,
          description,
          publishDescription,
          image,
        },
        refetchQueries: [getCountry(id)],
      })
        .then(({ data }) => {
          props.history.push(`/admin/countries/${data.editCountry.id}`);
        })
        .catch(() => props.onError());
    }
  }, 500);

  const apisDataRequiredOptions = [
    { value: 'none', label: 'None' },
    { value: 'standard', label: 'Standard' },
    { value: 'advanced', label: 'Advanced' },
  ];

  const publishDescriptionOptions = [
    { value: true, label: t('shared.true') },
    { value: false, label: t('shared.false') },
  ];

  let continentOptions;

  if (loading) {
    continentOptions = [];
  } else {
    continentOptions = data.continents.edges.map(continent => ({
      value: continent.node.id.toString(),
      label: continent.node.name,
    }));
  }

  const onFileUpload = files => {
    setImage(files[0]);
  };
  return (
    <div className="common-form">
      <div className="common-form__title">
        {isNewRecord
          ? t('shared.new', { entityName: t('countries.show.title') })
          : t('shared.editTitle', { entityName: t('countries.show.title') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnEdit, callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-10 col-bleed">
            {translationsAttributes &&
              translationsAttributes.map(
                (translationsAttribute, index) =>
                  !translationsAttribute._destroy && (
                    <NameTranslationForm
                      key={translationsAttribute.id}
                      translationsAttribute={translationsAttribute}
                      formIndex={index}
                      setTranslationsAttributes={setTranslationsAttributes}
                      translationsAttributes={translationsAttributes}
                    />
                  ),
              )}
          </div>
          <div className="col-lg-10 col-bleed-x col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field country-form__code">
                <Input
                  label={t('countries.attributes.code')}
                  value={code}
                  onChange={onCodeChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field country-form__publish-description">
                <SelectBox
                  label={t('countries.attributes.publishDescription')}
                  value={{
                    value: publishDescription,
                    label: t(`shared.boolean`, {
                      context: publishDescription,
                      defaultValue: publishDescription ? 'True' : 'False'
                    })
                  }}
                  options={publishDescriptionOptions}
                  onChange={onPublishDescriptionChange}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field country-form__phone-code">
                <Input
                  label={t('countries.attributes.phoneCode')}
                  value={phoneCode}
                  onChange={onPhoneCodeChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 col-bleed-y">
              <div className="common-form__field country-form__continent">
                <SelectBox
                  label={t('countries.attributes.continent')}
                  value={continentOptions.find(option =>
                      option.value === continentId.toString())}
                  options={continentOptions}
                  onChange={onContinentIdChange}
                  renderNoResultsFound={() => <NoResultsFound />}
                  isClearable={false}
                />
              </div>
              <div className="common-form__field country-form__apis-data-required">
                <SelectBox
                  label={t('countries.attributes.apisDataRequired')}
                  value={apisDataRequiredOptions.find(option =>
                      option.value === apisDataRequired)}
                  options={apisDataRequiredOptions}
                  onChange={onApisDataRequiredChange}
                  isClearable={false}
                />
              </div>
            </div>
            <div className="col-8 col-bleed-y">
              <div className="common-form__field country-form__description">
                <Textarea
                  label={t('countries.attributes.description')}
                  value={description}
                  onChange={onDescriptionChange}
                  placeholder={''}
                />
              </div>
            </div>
          </div>
          <div className="col-7 col-bleed-y">
            <h2>{t('countries.attributes.image')}</h2>
            {previousImage && (
              <div className="common-form__previous-image">
                <img src={imageUrl(previousImage)} alt="preview" />
              </div>
            )}
            <FileUpload onFileUpload={onFileUpload} multiple={false} />
            {!isNewRecord && (
              <UnsplashImageSearch
                setPreviousImage={setPreviousImage}
                imageableType={'Country'}
                imageableId={id}
              />
            )}
          </div>
        </div>
      </form>
      <div className="common-form__submit-button common-form__submit-button--with-margin">
        <Button
          label={isNewRecord ? t('shared.create') : t('shared.update')}
          onClick={submitToBackEnd}
        />
      </div>
    </div>
  );
};

export default WithErrorHandler(CountryForm);
