import React from 'react';
import { useTranslation } from 'react-i18next';
import availableLanguageOptions from '../../helpers/availableLanguageOptions';
import SelectBox from '@wtag/rcl-select-box';

const LanguageSelection = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;

  return (
    <div className="language-selection">
      <SelectBox
        value={availableLanguageOptions.find(option => option.value === language)}
        onChange={selectedOption => i18n.changeLanguage(selectedOption.value)}
        options={availableLanguageOptions}
        width="small"
        create={false}
        persist={false}
        isClearable={false}
      />
    </div>
  );
};

export default LanguageSelection;
