import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import ErrorComponent from '../common/ErrorComponent';
import WithErrorHandler from '../common/WithErrorHandler';
import CheckBox from '../rclComponents/CheckBox';
import clearCache from '../../helpers/clearCache';
import PropTypes from 'prop-types';

import { CREATE_PLATFORM_FEATURE } from '../../graphql/mutations/platformFeature';
import { GET_PLATFORM_FEATURE_CATEGORIES, GET_PLATFORM_FEATURES } from '../../graphql/queries/platformFeature';
import { all } from '../../graphql/queries/prepareQueryParams';
import { debounce } from 'lodash';

const PlatformFeatureLabelForm = props => {
  const { t } = useTranslation();

  const [createPlatformFeature, callbackDataOnCreate] = useMutation(CREATE_PLATFORM_FEATURE, {
    update: cache => clearCache(cache, /PlatformFeature/),
  });

  const { loading: platformFeatureListLoading, data: platformFeatureListData } = useQuery(
    GET_PLATFORM_FEATURE_CATEGORIES,
  );

  const userId = props.userId;
  const [parentId, setParentId] = useState('');
  const [title, setTitle] = useState('');
  const [nestedLabel, setNestedLabel] = useState(false);

  const onPlatformFeatureLabelIdChange = selectedOption => {
    setParentId(selectedOption.value);
  };

  const onPlatformFeatureLabelChange = value => {
    setTitle(value);
  };

  const submitToBackEnd = debounce(() => {
    props.resetError();
    createPlatformFeature({
      variables: { title, parentId, userId },
      refetchQueries: [all(GET_PLATFORM_FEATURE_CATEGORIES, parentId), all(GET_PLATFORM_FEATURES)],
    })
      .then(() => {
        props.history.push('/admin/platform_features');
      })
      .catch(() => props.onError());
  }, 500);

  let platformFeatureLabelOptions = [];
  if (!platformFeatureListLoading) {
    platformFeatureLabelOptions = platformFeatureListData.platformFeatureCategories.map(
      feature => ({
        value: feature.value,
        label: feature.label,
      }),
    );
  }

  return (
    <div className="common-form">
      <div className="common-form__title">
        {t('shared.new', {entityName: t('platformFeatures.attributes.label') })}
      </div>
      <div className="common-form__error-message">
        {props.hasError && (
          <div className="col-6">
            <ErrorComponent error={{ callbackDataOnCreate }} />
          </div>
        )}
      </div>
      <form className="common-form__container">
        <div className="grid">
          <div className="col-lg-8 col-md-10 col-sm-10 col-xs-10">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-bleed-y">
              <div className="common-form__field platform-feature-label-form__label">
                <Input
                  label={t('platformFeatures.attributes.label')}
                  value={title}
                  onChange={onPlatformFeatureLabelChange}
                  isClearable={false}
                />
              </div>
              <div className="col-grid col-bleed-x align-start common-form__field">
                <CheckBox
                  size="huge"
                  name="nestedLabel"
                  label={t('platformFeatures.buttons.nested')}
                  checked={nestedLabel}
                  onChange={() => setNestedLabel(!nestedLabel)}
                />
              </div>
              {nestedLabel && (
                <div className="common-form__field platform-feature-label-form__platform-feature-label">
                  <SelectBox
                    width="full"
                    label={t('platformFeatures.attributes.label')}
                    value={platformFeatureLabelOptions.find(option => option.value === parentId)}
                    options={platformFeatureLabelOptions}
                    onChange={onPlatformFeatureLabelIdChange}
                    isClearable={false}
                  />
                </div>
              )}
              <div className="common-form__submit-button">
                <Button label={t('shared.create')} onClick={submitToBackEnd} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

PlatformFeatureLabelForm.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default WithErrorHandler(PlatformFeatureLabelForm);
