import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Input from '@wtag/rcl-input';
import { debounce } from 'lodash';

import { itemsPerPage, maxPageNumberForFullLoad } from '../../variables';
import Header from '../common/Header';
import Paginate from '../common/Paginate';
import EmptyContentPlaceholder from '../common/EmptyContentPlaceholder';
import IconButton from '../rclComponents/IconButton';
import MetaIcon from '../icon/Icon';
import { GET_CITIES, GET_CURSORS_FOR_CITIES } from '../../graphql/queries/city';
import { DELETE_CITY } from '../../graphql/mutations/city';
import GET_COUNT_OF_TOTAL_CURSORS from '../../graphql/queries/totalCursorsCount';
import SpinnerIcon from '../../assets/icons/spinner.svg';
import CityTableRow from './CityTableRow';
import isEntityAvailable from '../../helpers/isEntityAvailable';

const CityList = () => {
  const { t } = useTranslation();
  const CURSOR_NUMBER_MULTIPLIER = 4;

  const [cursorState, setCursorState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(3);
  const [currentCursor, setCurrentCursor] = useState(null);
  const [searchParam, setSearchParam] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [cursors, setCursors] = useState([]);
  const [cursorFetchNumber, setCursorFetchNumber] = useState(
    itemsPerPage * CURSOR_NUMBER_MULTIPLIER,
  );
  const [requiredLengthToSearch, setRequiredLengthToSearch] = useState(2);
  const CITIES = 'cities';

  const [deleteItem] = useMutation(DELETE_CITY);
  const cursorData = useQuery(GET_CURSORS_FOR_CITIES, {
    variables: {
      first: cursorFetchNumber,
      after: currentCursor,
      query: searchParam,
    },
    fetchPolicy: 'network-only',
  });

  const cursorCountData = useQuery(GET_COUNT_OF_TOTAL_CURSORS, {
    variables: {
      modelName: CITIES,
      query: searchParam,
    },
  });

  const { client, loading, data } = useQuery(GET_CITIES, {
    variables: {
      first: itemsPerPage,
      after: currentCursor,
      query: searchParam,
    },
    fetchPolicy: 'network-only',
  });

  const callSearchParam = useRef(
    debounce(async inputParam => {
      setSearchParam(inputParam);
    }, 1000),
  );

  const resetSearch = () => {
    setSearchParam('');
    setSearchValue('');
  };

  const postIcon = () => {
    if (loading) {
      return <img className="list__search-post-icon" src={SpinnerIcon} alt="spinner" />;
    }
    return (
      <IconButton
        size="large"
        className="list__search-post-icon"
        isIconOnly={true}
        icon={<MetaIcon name="close" />}
        onClick={resetSearch}
      />
    );
  };

  const forcePage = currentPage - 1;

  const listAttributes = ['name', 'locale'];

  const changePage = useCallback(
    value => {
      setCurrentPage(value.selected + 1);
      const cursorIndexFromCurrentPage = value.selected * itemsPerPage - 1;
      if (cursorIndexFromCurrentPage < 0) {
        setCurrentCursor(null);
      } else {
        setCurrentCursor(cursors[cursorIndexFromCurrentPage]);
      }
    },
    [cursors],
  );

  useEffect(() => {
    if (cursorCountData && cursorCountData.data && !cursorCountData.loading) {
      const count = cursorCountData.data.totalCursorsCount / itemsPerPage;
      const pageCount = Math.ceil(count);
      setRequiredLengthToSearch(2);
      if (pageCount <= maxPageNumberForFullLoad) {
        setCursorFetchNumber(null);
      } else {
        setCursorFetchNumber(itemsPerPage * CURSOR_NUMBER_MULTIPLIER);
      }
      if (pageCount < currentPage) {
        changePage({ selected: 0 });
      }
      setPageCount(pageCount);
    }
    if (cursorData && cursorData.data && !cursorData.loading && !cursorState) {
      setCursorState(true);
      const oldCursors = Object.assign([], cursors);
      const newCursors = cursorData.data[CITIES].edges.map(edge => edge.cursor);
      const finalCursors = oldCursors.concat(
        newCursors.filter(newCursor => oldCursors.indexOf(newCursor) < 0),
      );
      setCursors(finalCursors);
    }
  }, [cursorCountData, cursorData, changePage, currentPage, cursors, cursorState]);

  const resetCities = () => client.resetStore();

  const onDelete = id => {
    window.confirm(t('cities.index.confirmDelete')) &&
      deleteItem({
        variables: { id },
      }).then(() => resetCities());
  };

  const onSearchParamChange = value => {
    setSearchValue(value);
    if (value === '' || value.length >= requiredLengthToSearch) {
      callSearchParam.current(value);
    }
  };

  return (
    <div className="list">
      <Header title="nav.cities" linkTo="/admin/cities/new" buttonLabel="shared.addNew" />
      <div>
        <Input
          label={t('list.search.label')}
          value={searchValue}
          onChange={onSearchParamChange}
          placeholder={t('cities.index.searchPlaceholder')}
          postIcon={postIcon()}
        />
      </div>
      <div>
        {loading && <div className="lds-dual-ring" />}
        {!loading && (
          <React.Fragment>
            {isEntityAvailable(data, CITIES) ? (
              <>
                <div className="list__pagination">
                  <Paginate
                    pageCount={pageCount}
                    onPageChange={changePage}
                    forcePage={forcePage}
                    marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                  />
                </div>
                <div className="list__header">
                  {listAttributes.map(item => (
                    <div className="list__value list__value-title" key={item}>
                      <p>{t(`cities.attributes.${item}`)}</p>
                    </div>
                  ))}
                  <div className="list__value list__value--actions">
                    <p>{t('list.header.actions')}</p>
                  </div>
                </div>
                <div className="list__table">
                  {data[CITIES].edges.map(data => (
                    <CityTableRow
                      data={data.node}
                      onDelete={onDelete}
                      key={data.node.id}
                      listAttributes={listAttributes}
                      resetCities={resetCities}
                    />
                  ))}
                </div>
                <div className="list__pagination">
                  <Paginate
                    pageCount={pageCount}
                    onPageChange={changePage}
                    forcePage={forcePage}
                    marginPagesDisplayed={cursorFetchNumber ? 0 : 1}
                  />
                </div>
              </>
            ) : (
              <EmptyContentPlaceholder
                entityName={t('nav.cities').toLowerCase()}
                modelName={'City'}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CityList;
